import React,{useState,useEffect} from 'react';
import Layout from './Layout';
import NavBar from './NavBar';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import * as Common from '../common/ImportUserEssentials';
import Settings from '../common/SettingsValue';
import styled from 'styled-components';
import Modal from 'react-bootstrap/Modal';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { confirmAlert } from 'react-confirm-alert';
import TagManager from 'react-gtm-module';

const Tab = styled.button`
	font-size: 16px;
	padding: 10px 60px;
	cursor: pointer;
	opacity: 0.6;
	background: white;
	border: 0;
	outline: 0;
	background-color: #ecf0f1f2;
	${({ active }) =>
		active &&
		`
		border-bottom: 2px solid black;
		opacity: 1;
	`}
`;
const ButtonGroup = styled.div`
	display: flex;
`;

export default function Subscription(props){
    const [einvoice_status, setEinvoiceStatus]      = useState(0);
    const [saveBtnDisabled,setSaveBtnDisabled]      = useState('');
    // nic user and password fields
    const [einvNicUser,setEinvNicUser]      = useState('');
    const [einvNicPassword,setEinvNicPassword]      = useState('');
    const [ewayNicUser,setEwayNicUser]      = useState('');
    const [ewayNicPassword,setEwayNicPassword]      = useState('');
    const [gstNicUser,setGstNicUser]      = useState('');
    const [einvValidateBlock,setEinvValidateBlock]      = useState(false);
    const [ewayValidateBlock,setEwayValidateBlock]      = useState(false);
    const [gstinValidateBlock,setGstinValidateBlock]      = useState(false);
    // const [validateFlag, setValidateFlag] = useState('');

    const [ewayStatus, setEwayStatus] = useState(0);
    const [gstStatus, setGstStatus] = useState(0);

    const [einvUser, setEinvUser] = useState('');
    // const [ewbUser, setEwbUser] = useState('');
    const [gstUser, setGstUser] = useState('');
    
    const [einvPass, setEinvPass] = useState('');
    // const [ewbPass, setEwbPass] = useState('');
    // const [gstPass, setGstPass] = useState('');

    // const [einvUpdated, setEinvUpdated] = useState(false);
    // const [ewbUpdated, setEwbUpdated] = useState(false);
    // const [gstUpdated, setGstUpdated] = useState(false);
    const [isSubTabEnabled, setIsSubTabEnabled] = useState(false);
    const [einvPlan, setEinvPlan] = useState(false);
    const [ewayPlan, setEwayPlan] = useState(false);
    const [gstPlan, setGstPlan] = useState(false);
    const [toggle, setToggle] = useState(true);
    // const [planPeriod, setPlanPeriod] = useState('yearly');
    const [plan, setPlan] = useState('');
    const [complianceModal, setComplianceModal] = useState(false)
    // const [profileType, setProfileType] = useState('');
    const [planType, setPlanType] = useState('');
    const [planDays, setPlanDays] = useState('');
    const [contact, setContact] = useState(false);
	const [customAmt,setCustomAmt] = useState('');
	const [gstRate,setGstRate] = useState('');
	const [finalTotalAmt,setFinalTotalAmt] = useState('');
	const [walletAmount,setWalletAmount] = useState(<i className="bx bx-loader-alt bx-spin"></i>);
    const pricingHeadTypes = ['Subscription', 'Credits'];
    const [activeTab, setActiveTab] = React.useState(pricingHeadTypes[0]);
	const [firstTimeLogin,setFirstTimeLogin] = React.useState(false);
	const [youtubeModal,setYoutubeModal] = React.useState(false);
	const handleYoutubeViewClose = () => setYoutubeModal(false);
    
	const [creditPlans,setCreditPlans] = React.useState('');
	const [subscription_r,setsubscription_r] = React.useState(true);
	const navigate = Common.useNavigate();
	useEffect(() => {
		document.title = 'Pricing Plan | '+props.site_name;
		const hostname = window.location.hostname;
		if( hostname === 'mart.fynone.com' ){
			const tagManagerArgs = {
				gtmId: 'G-RF6E5H5MTZ'
			}

			TagManager.initialize(tagManagerArgs);
		}
		// User permissions
		let userPerm = JSON.parse(localStorage.getItem('fo_userperm'));
		if( userPerm && Object.keys(userPerm).length > 0 ){
			//Read Permission
			setsubscription_r((userPerm[6]['view'] !== undefined && userPerm[6]['view'] === '0') ? false : true);
		}
        checkFirstTimeLogin()
		if (firstTimeLogin === true) {
			setYoutubeModal(true);
			updateFirstTimeLogin();
		}
        setToggle(true);
		// Fetch wallet Balance
		Common.axios({
            method: 'get',
            url:props.url+'wallet/balance/'+localStorage.getItem('fo_profileid'),
        }).then(function (response) {
			if(response.data.success){
				setWalletAmount(response.data.data);
			}
		});
        Common.axios({
            method: 'get',
            url:props.url+'subscription/details/'+localStorage.getItem('fo_profileid'),
        }).then(function (response) {
            if(response.data.success){
				setEinvoiceStatus(response.data.data.einvoice_status);
                setSaveBtnDisabled(response.data.data.disable_btn);
                setEwayStatus(response.data.data.ewb_status);
                setGstStatus(response.data.data.gst_status);
                setPlanType(response.data.data.plan_type);
                setPlanDays(response.data.data.days);
                
                // username
                setEinvUser(response.data.data.nic_user)
                // setEwbUser(response.data.data.ewb_user)
                setGstUser(response.data.data.gst_user)

                // password
                setEinvPass(response.data.data.nic_pass)
                // setEwbPass(response.data.data.ewb_pass)
                // setGstPass(response.data.data.gst_pass)

                response.data.data.is_sub_tab_enabled === '1' ? setIsSubTabEnabled(true) : setIsSubTabEnabled(false)
                setPlan(response.data.data.sub_plan);
                // setProfileType(response.data.data.type);
                if (response.data.data.sub_plan === 'PRO' || response.data.data.sub_plan === 'Enterprise') {
                    setEinvPlan(true);
                    setEwayPlan(true);
                    setGstPlan(true);
                }
            }
        }).catch(error => console.log(error));
		const creditsPlan = async() => {
			let plans = await Common.axios.get(props.url+'settings/param/CREDITS_PLAN').then(response => {
				if( response.data.success ){
					return JSON.parse(response.data.data);
				}
			})
			if( plans.length > 0 ){
				let html = plans.map((plan,index) => (
					<div className="col-sm-4" key={'price_'+index} id={'price_'+index}>
						<div className="card">
							<div className="card-body text-center">
								<h5 className="card-title">{plan.name}</h5>
								<h6 className="card-subtitle mb-2 text-muted">Credits: {plan.credits}</h6>
								<h6 className="card-subtitle mb-2 text-muted">Price: ₹{plan.price}</h6>
								<button type='button' onClick={() => addCredits()} className="btn btn-primary btn-sm ppbtn">Pay</button>
							</div>
						</div>
					</div>
				))
				setCreditPlans(html);
			}
		}
		creditsPlan();
	}, [props,firstTimeLogin]);
	if( !subscription_r ){
		navigate("/not-authorized");
	}
    const checkFirstTimeLogin = () => {
		Common.axios({
			method: 'post',
			url:props.url+'user/checkFirstTimeLogin',
			data: {
				uid: localStorage.getItem('fo_loggedin'),
			},
		}).then(function (response) {
			if( response.data.success ){
				if (response.data.is_first_login === '1') {
					setFirstTimeLogin(true)
					
				}
				return false;
			}
			Common.toast.dismiss();
			Common.toast.error(response.data.message);
		}).catch(error => console.log(error));
	}
	const updateFirstTimeLogin = () => {
		Common.axios({
			method: 'post',
			url:props.url+'user/updateFirstTimeLogin',
			data: {
				uid: localStorage.getItem('fo_loggedin'),
			},
		}).then(function (response) {
			if( response.data.success ){
				return false;
			}
			Common.toast.dismiss();
			Common.toast.error(response.data.message);
		}).catch(error => console.log(error));
	}
	const handleClick = () => {
		setYoutubeModal(false);
	}
	const subscriptionStatus = (checked) => {
		Common.axios({
			method: 'post',
			url: props.url+'subscription/subChangeStatus',
			data: {pid:localStorage.getItem('fo_profileid'),einvoice_status:checked}
		}).then(function (response) {
            console.log(response);
            if(response.data.success){
				Common.toast.success(response.data.message);
				return false;
            }
			Common.toast.error(response.data.message);
        }).catch(error => console.log(error));
	}
    const handleValidationEinv = (e) => {
        e.preventDefault();
        if (einvNicUser === '' || einvNicPassword === '') {
            Common.toast.error('E-Invoice credentials cannot be blank');
            return false;
        } else {
            Common.axios({
                method: 'post',
                url: props.url+'subscription/validateNicCredentials',
                // data: {pid:localStorage.getItem('fo_profileid'),einvoice_status:checked}
                data: {
                    pid:localStorage.getItem('fo_profileid'),
                    validateFlag:'einv',
                    user:einvNicUser,
                    password:einvNicPassword
                }
            }).then(function (response) {
                if(response.data.success){
                    Common.toast.success(response.data.message);
                    // setEinvUpdated(true);
                    window.location.reload();
                    return false;
                }
                Common.toast.error(response.data.message);
            }).catch(error => console.log(error));
        }
    }
    const handleValidationEway = (e) => {
        e.preventDefault();
        if (ewayNicUser === '' || ewayNicPassword === '') {
            Common.toast.error('Eway Bill credentials cannot be blank');
            return false;
        } else {
            Common.axios({
                method: 'post',
                url: props.url+'subscription/validateNicCredentials',
                data: {
                    pid:localStorage.getItem('fo_profileid'),
                    validateFlag:'eway',
                    user:ewayNicUser,
                    password:ewayNicPassword
                }
            }).then(function (response) {
                if(response.data.success){
                    Common.toast.success(response.data.message);
                    // setEwbUpdated(true);
                    window.location.reload();
                    return false;
                }
                Common.toast.error(response.data.message);
            }).catch(error => console.log(error));
        }
        
    }
    const updateEinvStatus = (checked) => {
        checked === true ? setEinvoiceStatus('1') : setEinvoiceStatus('0');
        Common.axios({
            method: 'post',
            url: props.url+'subscription/updateStatus',
            data: {
                pid:localStorage.getItem('fo_profileid'),
                validateFlag:'einv',
                status: checked,
            }
        }).then(function (response) {
            if(response.data.success){
                Common.toast.success(response.data.message);
                // window.location.reload();
                return false;
            }
            Common.toast.error(response.data.message);
        }).catch(error => console.log(error));
    }
    const updateEwbStatus = (checked) => {
        checked === true ? setEwayStatus('1') : setEwayStatus('0');
        Common.axios({
            method: 'post',
            url: props.url+'subscription/updateStatus',
            data: {
                pid:localStorage.getItem('fo_profileid'),
                validateFlag:'eway',
                status: checked,
            }
        }).then(function (response) {
            if(response.data.success){
                Common.toast.success(response.data.message);
                // setEwbUpdated(true);
                // window.location.reload();
                return false;
            }
            Common.toast.error(response.data.message);
        }).catch(error => console.log(error));
    }
    const updateGstStatus = (checked) => {
        checked === true ? setGstStatus('1') : setGstStatus('0');
        Common.axios({
            method: 'post',
            url: props.url+'subscription/updateStatus',
            data: {
                pid:localStorage.getItem('fo_profileid'),
                validateFlag:'gst',
                status: checked,
            }
        }).then(function (response) {
            if(response.data.success){
                Common.toast.success(response.data.message);
                // setEwbUpdated(true);
                // window.location.reload();
                return false;
            }
            Common.toast.error(response.data.message);
        }).catch(error => console.log(error));
    }
    const handleValidationGst = (e) => {
        e.preventDefault();
        if (gstNicUser === '') {
            Common.toast.error('GST credentials cannot be blank');
            return false;
        } else {
            Common.axios({
                method: 'post',
                url: props.url+'subscription/validateNicCredentials',
                // data: {pid:localStorage.getItem('fo_profileid'),einvoice_status:checked}
                data: {
                    pid:localStorage.getItem('fo_profileid'),
                    validateFlag:'gst',
                    user:gstNicUser,
                    password: ''
                }
            }).then(function (response) {
                if(response.data.success){
                    Common.toast.success(response.data.message);
                    // setGstUpdated(true);
                    window.location.reload();
                    return false;
                }
                Common.toast.error(response.data.message);
            }).catch(error => console.log(error));
        }
    }
    /* const handleToggle = (bool) => {
        setToggle(bool === 'true' ? false : true);
        setPlanPeriod(bool === 'true' ? 'monthly': 'yearly');
    } */
    const upgradePlan = (planType) => {
        setContact(true);
        // DO NOT UNCOMMENT BELOW CALL TILL PAYMENT GATEWAY IS IMPLEMENTED
        /* Common.axios({
            method: 'post',
            url: props.url+'subscription/upgradePlan',
            // data: {pid:localStorage.getItem('fo_profileid'),einvoice_status:checked}
            data: {
                pid:localStorage.getItem('fo_profileid'),
                planType: planType,
                plan_period: planPeriod,
            }
        }).then(function (response) {
            if(response.data.success){
                Common.toast.success(response.data.message);
                window.location.reload();
                return false;
            }
            Common.toast.error(response.data.message);
        }).catch(error => console.log(error)); */
    }
    const addCredits = () => {
        setContact(true);
    }
    const enrolPlan = (planType,callFrom = 0) => {
        Common.axios({
            method: 'post',
            url: props.url+'subscription/enrolPlan',
            // data: {pid:localStorage.getItem('fo_profileid'),einvoice_status:checked}
            data: {
                pid:localStorage.getItem('fo_profileid'),
                planType: planType,
            }
        }).then(function (response) {
            if(response.data.success){
                Common.toast.success(response.data.message);
                if(callFrom === 1){
                    navigate('/mart');
                    return false;
                }else{
                    window.location.reload();
                    return false;
                }
            }
            Common.toast.error(response.data.message);
        }).catch(error => console.log(error));
    }
    const handleFreeEnrole =(e,planType) => {
        e.preventDefault();
        confirmAlert({
            title: 'Enrol Free Plan',
            message: 'You will be redirected to FynMart - the community eCommerce platform',
            buttons: [{
                label: 'Yes',
                onClick: () => {
                    enrolPlan(planType,1);
                }
            },
            {
                label: 'No',
                onClick: () => { console.log(true); }
            }]
        });
    }
    const [showPricingPlan,setShowPricingPlan]     = React.useState(true);
    const [showCreditWallet,setShowCreditWallet] = React.useState(false);
	function TabGroup() {
		const handleTab = penColChecked => {
			setActiveTab(penColChecked);
			if(penColChecked === "Subscription"){
				setShowPricingPlan(true);
				setShowCreditWallet(false);
			}
			else{
				setShowCreditWallet(true);
				setShowPricingPlan(false);
			}
		};
		return (
			<>
			<ButtonGroup className="tabWithInfo">
				{pricingHeadTypes.map(type => (
				<Tab
					key={type}
					active={activeTab === type}
					onClick={() => handleTab(type)}
				>
					{type}
				</Tab>
				))}
			</ButtonGroup>
			</>
		);
	}
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
    };
    const customAmountCal = (custom_amt) => {
        if(custom_amt > 0){
            let final_amt =  (Number(custom_amt) + Number(custom_amt * (18 / 100))).toFixed(2);
            setCustomAmt(custom_amt);
            setGstRate('18%');
            setFinalTotalAmt(final_amt);
        }else{
            setCustomAmt('');
            setGstRate('');
            setFinalTotalAmt('');
        }
    }
    /* const contactUs = () => {
        setContact(true)
    } */
    return (
        <div className="layout-wrapper layout-menu-fixed layout-content-navbar">
            <div className="layout-container">
                <Layout site_logo={props.site_logo} label="pricing_plan" site={props.site_name}/>
                <div className="layout-page">
                    <NavBar site={props.site_name} url={props.url}/>
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                            <h4 className="fw-bold py-3 mb-3"><span className="text-muted fw-light">Account Management /</span> Pricing Plan</h4>
                            <div className="row">
								<div className="pb-3">
									<div className="col-md-12">
										<TabGroup/>
									</div>
								</div>
                                <div id="sdt_cont" className="col-sm-12">
									{showPricingPlan && (<>
                                        <div className="row">
                                            <div className='col-lg-12'>
                                                <h2 className="text-center mb-2 mt-0 mt-md-2">Find the right plan for your business</h2>
                                                <p className="text-center"> 21-days free trial (15 standard + 6 extended). </p>
                                            </div>
                                            <div className="col-md-12">
                                                <Carousel responsive={responsive}>
                                                    <div className={plan === 'Free' ? 'card border-primary border rounded shadow-none mx-3' : 'card border rounded shadow-none mx-3'}>
                                                        <div className="card-body">
                                                            <div className="my-3 pt-2 text-center">
                                                                {/* <img src="/assets/img/bookmark.png" alt="Basic" height="80" /> */}
                                                                <i className='bx bxs-bookmark-alt bx-lg text-primary'></i>
                                                            </div>
                                                            <h3 className="card-title text-center text-capitalize mb-1">Free</h3>
                                                            <p className="text-center">For retail stores and kirana shops</p>
                                                            <div className="text-center">
                                                                <div className="d-flex justify-content-center">
                                                                <sup className="h6 pricing-currency mt-3 mb-0 me-1 text-primary">₹</sup>
                                                                <h1 className="display-4 mb-0 text-primary">0</h1>
                                                                <sub className="h6 pricing-duration mt-auto mb-2 text-muted fw-normal">/month</sub>
                                                                </div>
                                                                {toggle === true ? <><small className="price-yearly price-yearly-toggle text-muted">Free for lifetime</small></> : <></>}
                                                            </div>

                                                            <ul className="ps-3 my-4 list-unstyled">
                                                                <li className="mb-2"><span className="badge badge-center w-px-20 h-px-20 rounded-pill bg-label-primary me-2"><i className="bx bx-check bx-xs"></i></span>Online store with an invite only option to customers</li>
                                                                <li className="mb-2"><span className="badge badge-center w-px-20 h-px-20 rounded-pill bg-label-primary me-2"><i className="bx bx-check bx-xs"></i></span>Customers can seamlessly select, order, pay & track</li>
                                                                <li className="mb-2"><span className="badge badge-center w-px-20 h-px-20 rounded-pill bg-label-primary me-2"><i className="bx bx-check bx-xs"></i></span>Provide exclusive deals and offers to select customers</li>
                                                                <li className="mb-2"><span className="badge badge-center w-px-20 h-px-20 rounded-pill bg-label-primary me-2"><i className="bx bx-check bx-xs"></i></span>Complimentary 500 credits on first sign up. Platform fee of 5 credits per customer order</li>
                                                            </ul>

                                                            {plan === 'Basic' || plan === 'Standard' || plan === 'PRO' || plan === 'Enterprise' ? <></> : <>
                                                                {plan === 'Free' ? <><p className="text-center mb-2" style={{'color': '#71dd37'}}>Your Current Plan</p></>: <><button type='button' onClick={(e) => handleFreeEnrole(e,'F') } className="btn btn-primary d-grid w-100">Enrol</button></>}
                                                            </> }
                                                            <div className="text-center">
                                                                <a href="https://youtu.be/3_2cZqFaE2Y" target="_blank" rel="noreferrer"><i className='text-danger bx bxl-youtube bx-md'></i></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={plan === 'Basic' ? 'card border-primary border rounded shadow-none mx-3' : 'card border rounded shadow-none mx-3'}>
                                                        <div className="card-body">
                                                        <div className="my-3 pt-2 text-center">
                                                            {/* <img src="/assets/img/bookmark.png" alt="Basic" height="80" /> */}
                                                            <i className='bx bxs-book-bookmark bx-lg text-primary'></i>
                                                        </div>
                                                        <h3 className="card-title text-center text-capitalize mb-1">Basic</h3>
                                                        <p className="text-center">For startups and small companies</p>
                                                        <div className="text-center">
                                                            <div className="d-flex justify-content-center">
                                                            <sup className="h6 pricing-currency mt-3 mb-0 me-1 text-primary">₹</sup>
                                                            <h1 className="display-4 mb-0 text-primary">124</h1>
                                                            <sub className="h6 pricing-duration mt-auto mb-2 text-muted fw-normal">/month</sub>
                                                            </div>
                                                            {toggle === true ? <><small className="price-yearly price-yearly-toggle text-muted">Billed Annually - ₹ 1488/-</small></> : <></>}
                                                        </div>

                                                        <ul className="ps-3 my-4 list-unstyled">
                                                            <li className="mb-2"><span className="badge badge-center w-px-20 h-px-20 rounded-pill bg-label-primary me-2"></span> <strong>Everything in Free+</strong></li>
                                                            <li className="mb-2"><span className="badge badge-center w-px-20 h-px-20 rounded-pill bg-label-primary me-2"><i className="bx bx-check bx-xs"></i></span>Business profiles with or without GST</li>
                                                            <li className="mb-2"><span className="badge badge-center w-px-20 h-px-20 rounded-pill bg-label-primary me-2"><i className="bx bx-check bx-xs"></i></span>Generate bills & Tax invoices</li>
                                                            <li className="mb-2"><span className="badge badge-center w-px-20 h-px-20 rounded-pill bg-label-primary me-2"><i className="bx bx-check bx-xs"></i></span>Income & Collections management</li>
                                                            <li className="mb-2"><span className="badge badge-center w-px-20 h-px-20 rounded-pill bg-label-primary me-2"><i className="bx bx-check bx-xs"></i></span>Expense & Payment management</li>
                                                            <li className="mb-0"><span className="badge badge-center w-px-20 h-px-20 rounded-pill bg-label-primary me-2"><i className="bx bx-check bx-xs"></i></span>Inventory Management</li>
                                                            <li className="mb-0"><span className="badge badge-center w-px-20 h-px-20 rounded-pill bg-label-primary me-2 mt-2"><i className="bx bx-check bx-xs"></i></span>Unlimited user management with permission based access</li>
                                                        </ul>

                                                        {/* {plan === 'Free' || plan === 'Standard' || plan === 'PRO' || plan === 'Enterprise' ? <></> : <>
                                                            {plan === 'Basic' ? <><p className="text-center mb-2" style={{'color': '#71dd37'}}>Your Current Plan</p> {planDays < 0 && planType !== null ? <><button type='button' onClick={() => upgradePlan('B') } className="btn btn-primary d-grid w-100">Renew</button></> : <></>} </>: <><button type='button' onClick={() => enrolPlan('B') } className="btn btn-primary d-grid w-100">Enrol for free trial</button></>}
                                                        </> } */}

                                                        {plan === 'Basic' ? <><p className="text-center mb-2" style={{'color': '#71dd37'}}>Your Current Plan</p>  {planDays < 0 && planType !== null ? <><button type='button' onClick={() => upgradePlan('B') } className="btn btn-primary d-grid w-100">Renew</button></> : <></>}</>: <></>}
                                                        
                                                        {plan === '' ? 
                                                            <>
                                                                <button type='button' onClick={() => enrolPlan('B')} className="btn btn-primary d-grid w-100">Enrol for free trial</button>
                                                            </> : 
                                                            <>
                                                                {plan === 'PRO' || plan === 'Standard' || plan === 'Enterprise' ? <></> : <>
                                                                    {plan !== 'Basic' ? <> <button type='button' onClick={() => upgradePlan('B')} className="btn btn-primary d-grid w-100">Upgrade</button></> : <></>}
                                                                </>}
                                                            </> 
                                                        }
                                                            <div className="text-center">
                                                                <a href="https://youtu.be/qsrCuJkOi2w?si=siEwLt-VgKRTkWwC" target="_blank" rel="noreferrer"><i className='text-danger bx bxl-youtube bx-md'></i></a>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className={plan === 'Standard' ? 'card border-primary border rounded shadow-none mx-3' : 'card border rounded shadow-none mx-3'}>
                                                        <div className="card-body position-relative">
                                                        <div className="position-absolute end-0 me-4 top-0 mt-4">
                                                            <span className="badge bg-label-primary">Popular</span>
                                                        </div>
                                                        <div className="my-3 pt-2 text-center">
                                                            {/* <img src="/assets/img/wallet-round.png" alt="Popular" height="80" /> */}
                                                            <i className='bx bxs-wallet bx-lg text-primary'></i>
                                                        </div>
                                                        <h3 className="card-title text-center text-capitalize mb-1">Standard</h3>
                                                        <p className="text-center">For small to medium businesses</p>
                                                        <div className="text-center">
                                                            <div className="d-flex justify-content-center">
                                                            <sup className="h6 pricing-currency mt-3 mb-0 me-1 text-primary">₹</sup>
                                                            <h1 className="price-toggle price-yearly display-4 text-primary mb-0">199</h1>
                                                            <sub className="h6 text-muted pricing-duration mt-auto mb-2 fw-normal">/month</sub>
                                                            </div>
                                                            {toggle === true ? <><small className="price-yearly price-yearly-toggle text-muted">Billed Annually - ₹ 2388/-</small></> : <></>}
                                                            
                                                        </div>

                                                        <ul className="ps-3 my-4 list-unstyled">
                                                            <li className="mb-2"><span className="badge badge-center w-px-20 h-px-20 rounded-pill bg-label-primary me-2"></span> <strong>Everything in Basic+</strong></li>
                                                            <li className="mb-2"><span className="badge badge-center w-px-20 h-px-20 rounded-pill bg-label-primary me-2"><i className="bx bx-check bx-xs"></i></span>Accounting module with automated ledger creation</li>
                                                            <li className="mb-2"><span className="badge badge-center w-px-20 h-px-20 rounded-pill bg-label-primary me-2"><i className="bx bx-check bx-xs"></i></span>Financial Statements incl income statement, Balance sheet, Cashflow stmt</li>
                                                            <li className="mb-2"><span className="badge badge-center w-px-20 h-px-20 rounded-pill bg-label-primary me-2"><i className="bx bx-check bx-xs"></i></span>Asset management including auto depreciation calculation</li>
                                                            <li className="mb-2"><span className="badge badge-center w-px-20 h-px-20 rounded-pill bg-label-primary me-2"><i className="bx bx-check bx-xs"></i></span>Loans and investment management</li>
                                                        </ul>

                                                        {plan === 'Standard' ? <><p className="text-center mb-2" style={{'color': '#71dd37'}}>Your Current Plan</p>  {planDays < 0 && planType !== null ? <><button type='button' onClick={() => upgradePlan('S') } className="btn btn-primary d-grid w-100">Renew</button></> : <></>}</>: <></>}
                                                        
                                                        {plan === '' ? 
                                                            <>
                                                                <button type='button' onClick={() => enrolPlan('S')} className="btn btn-primary d-grid w-100">Enrol for free trial</button>
                                                            </> : 
                                                            <>
                                                                {plan === 'PRO' || plan === 'Enterprise' ? <></> : <>
                                                                    {plan !== 'Standard' ? <> <button type='button' onClick={() => upgradePlan('S')} className="btn btn-primary d-grid w-100">Upgrade</button></> : <></>}
                                                                </>}
                                                            </> 
                                                        }
															<div className="text-center">
                                                                <a href="https://youtu.be/pmTKHwI968A?si=OGTkr45t6WPwlwsP" target="_blank" rel="noreferrer"><i className='text-danger bx bxl-youtube bx-md'></i></a>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className={plan === 'PRO' ? 'card border-primary border rounded shadow-none mx-3' : 'card border rounded shadow-none mx-3'}>
                                                        <div className="card-body">

                                                        <div className="my-3 pt-2 text-center">
                                                            {/* <img src="/assets/img/briefcase-round.png" alt="Pro" height="80" /> */}
                                                            <i className='bx bxs-briefcase bx-lg text-primary'></i>
                                                        </div>
                                                        <h3 className="card-title text-center text-capitalize mb-1">PRO</h3>
                                                        <p className="text-center">Solution for big organizations</p>

                                                        <div className="text-center">
                                                            <div className="d-flex justify-content-center">
                                                            <sup className="h6 text-primary pricing-currency mt-3 mb-0 me-1">₹</sup>
                                                            <h1 className="price-toggle price-yearly display-4 text-primary mb-0">599</h1>
                                                            <sub className="h6 pricing-duration mt-auto mb-2 fw-normal text-muted">/month</sub>
                                                            </div>
                                                            {toggle === true ? <><small className="price-yearly price-yearly-toggle text-muted">Billed Annually - ₹ 7188/-</small></> : <></>}
                                                            
                                                        </div>

                                                        <ul className="ps-3 my-4 list-unstyled">
                                                            <li className="mb-2"><span className="badge badge-center w-px-20 h-px-20 rounded-pill bg-label-primary me-2"></span> <strong>Everything in Standard+</strong></li>
                                                            <li className="mb-2"><span className="badge badge-center w-px-20 h-px-20 rounded-pill bg-label-primary me-2"><i className="bx bx-check bx-xs"></i></span>Single click Einvoice generation with IRN number and QR code in pdf copy</li>
                                                            <li className="mb-2"><span className="badge badge-center w-px-20 h-px-20 rounded-pill bg-label-primary me-2"><i className="bx bx-check bx-xs"></i></span>EWay bill management including Delivery Challan</li>
                                                            <li className="mb-2"><span className="badge badge-center w-px-20 h-px-20 rounded-pill bg-label-primary me-2"><i className="bx bx-check bx-xs"></i></span>GST returns covering R1, 3B, ITC04 as well as vendor 2B reconciliation</li>

                                                            {plan === 'PRO' ? <li className="mb-0"><span className="badge badge-center w-px-20 h-px-20 rounded-pill bg-label-primary me-2"><i className="bx bx-check bx-xs"></i></span><button className='border-0 bg-white text-dark text-decoration-underline btn btn-sm' style={{'fontSize' : '0.9375rem'}} onClick={() => setComplianceModal(true)}>Compliance</button></li> 
                                                            : 
                                                            <></>}
                                                        </ul>
                                                        {plan === 'PRO' ? <p className="text-center mb-2" style={{'color': '#71dd37'}}>Your Current Plan</p> : <></>}
                                                        {plan === '' ? 
                                                            <>
                                                                <button type='button' onClick={() => enrolPlan('P')} className="btn btn-primary d-grid w-100">Enrol for free trial</button>
                                                            </> : 
                                                            <>
                                                                {plan === 'Enterprise' ? <></> : <>{plan !== 'PRO' || (planDays < 0 && planType !== null) ? <> <button type='button' onClick={() => upgradePlan('P')} className="btn btn-primary d-grid w-100">Upgrade</button></> : <><button type='button' onClick={() => upgradePlan('P')} className="btn btn-primary d-grid w-100">Renew</button></>}</>}
                                                            </> 
                                                        }
                                                            <div className="text-center">
                                                                <a href="https://youtu.be/TA1MTv5vpUg?si=otxikML4vh4Nkby9" target="_blank" rel="noreferrer"><i className='text-danger bx bxl-youtube bx-md'></i></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={plan === 'Enterprise' ? 'card border-primary border rounded shadow-none mx-3' : 'card border rounded shadow-none mx-3'}>
                                                        <div className="card-body">

                                                        <div className="my-3 pt-2 text-center">
                                                            {/* <img src="/assets/img/briefcase-round.png" alt="Enterprise" height="80" /> */}
                                                            <i className='bx bxs-rocket bx-lg text-primary'></i>
                                                        </div>
                                                        <h3 className="card-title text-center text-capitalize mb-1">Enterprise</h3>
                                                        <p className="text-center">Customized offerings & support services</p>

                                                        <div className="text-center">
                                                            <div className="d-flex justify-content-center">
                                                            <h4 className="price-toggle price-yearly  text-primary mb-0">Custom pricing</h4>
                                                            </div>
                                                        </div>

                                                        <ul className="ps-3 my-4 list-unstyled">
                                                            <li className="mb-2"><span className="badge badge-center w-px-20 h-px-20 rounded-pill bg-label-primary me-2"></span> <strong>Everything in PRO+</strong></li>
                                                            <li className="mb-2"><span className="badge badge-center w-px-20 h-px-20 rounded-pill bg-label-primary me-2"><i className="bx bx-check bx-xs"></i></span>Unlimited transactions with slab based pricing</li>
                                                            <li className="mb-2"><span className="badge badge-center w-px-20 h-px-20 rounded-pill bg-label-primary me-2"><i className="bx bx-check bx-xs"></i></span>Customized invoice sequence based on rules</li>
                                                            <li className="mb-2"><span className="badge badge-center w-px-20 h-px-20 rounded-pill bg-label-primary me-2"><i className="bx bx-check bx-xs"></i></span>Bulk excel upload for sales and purchase transactions in standard or custom format</li>
                                                            <li className="mb-2"><span className="badge badge-center w-px-20 h-px-20 rounded-pill bg-label-primary me-2"><i className="bx bx-check bx-xs"></i></span>End to end support for e-invoicing and GST filing requirements</li>

                                                            {plan === 'Enterprise' ? <li className="mb-0"><span className="badge badge-center w-px-20 h-px-20 rounded-pill bg-label-primary me-2"><i className="bx bx-check bx-xs"></i></span><button className='border-0 bg-white text-dark text-decoration-underline btn btn-sm' style={{'fontSize' : '0.9375rem'}} onClick={() => setComplianceModal(true)}>Compliance</button></li> 
                                                            : 
                                                            <></>}
                                                        </ul>
                                                        {plan === 'Enterprise' ? <p className="text-center mb-2" style={{'color': '#71dd37'}}>Your Current Plan</p> : <><button type='button' onClick={() => setContact(true)} className="btn btn-primary d-grid w-100">Contact</button></>}
                                                        </div>
                                                    </div>
                                                </Carousel>
                                                <div className="card mb-4">
                                                    <div className="card-body mt-3 ">
                                                        <div className='row mx-0 gy-3'>
                                                            <div>
                                                                <a href={props.pricing_logo} target='_blank' rel="noreferrer" className="app-brand-link">Compare Pricing Plans</a>
                                                                <Settings url={props.url} param='COMPARE_PLANS_NOTE'/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>)}
                                    {showCreditWallet && (<>
                                        <div className="card">
                                            <div className="card-body">
                                                <h5 className="card-title">
                                                    <div className="row">
														<div className="alert alert-info" role="alert">
                                                            <small className="text-dark">
																<b>Credits are used at</b><br></br>
																1. FynMart user orders - 5 credits on accepting a user order via FynMart pre-order tab.
															</small>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-9">Add to Credits</div>
                                                        <div className="col-sm-3 text-center">
                                                            <span className="float-md-end badge bg-info text-white">Total Credits: <span className="text-white">{Common.displayPrice(walletAmount)}</span></span>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="text-center my-3">Choose from our popular packs</div>
                                                        <div className="row mb-3">
                                                        {creditPlans}
														<small className="mt-2 text-danger">*prices are exclusive of GST at 18%</small>
                                                        </div>
                                                        {/*<hr/>
                                                        <div className="row">
                                                            <div className="col-sm-12">
                                                                <div className="table-responsive">
                                                                    <table className="table">
                                                                        <thead>
                                                                            <tr>
                                                                                <td align="left"><b>Enter custom amount</b></td>
                                                                                <td align="right"><b>Tax Rates</b>
                                                                                </td><td align="right"><b>Total Value</b></td>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td align="left"><input className="form-control" type="text" min="500" name="custom_amount" id="custom_amount" placeholder="Min 500 Credits" value={customAmt} onChange={(e) => { customAmountCal(Common.acceptOnlyDecimal(e.target.value)); }}/></td>
                                                                                <td align="right"><span id="gst_rates" style={{"color":"#000"}}>{gstRate}</span></td>
                                                                                <td align="right"><b><span style={{"color":"#000"}} id="total_value">{finalTotalAmt}</span></b></td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="mt-3 d-grid gap-2 col-2 mx-auto">
                                                                <button id="pbtn" onClick={() => addCredits()} className="btn btn-sm btn-primary text-white" type="button">Pay</button>
                                                            </div>
                                                        </div>*/}
                                                    </div>
                                                </h5>
                                            </div>
                                        </div>
                                    </>)}
                                </div>
                            </div>
                        </div>
                    </div>
                <Common.FYNTicket url={props.url}/>
            </div>
            </div>
            <Modal size='sm' show={contact} onHide={()=>setContact(false)} aria-labelledby="contact-modal" backdrop="static" keyboard={false} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Renewal / Upgrade</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <label className="col-12">Reach out to FynOne team for support</label>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal size='lg' show={complianceModal} onHide={()=>setComplianceModal(false)} aria-labelledby="contact-modal" backdrop="static" keyboard={false} centered>
                <Modal.Header closeButton>
                <Modal.Title>Compliance</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row'>
                        <div className="col-sm-4">
                            <h3 style={{'fontWeight': '300', 'color': '#566a7f'}}>e-Invoice</h3>
                            {isSubTabEnabled === true ? <p className="price">How to add Fynamics GSP: <a target='_blank' rel='noreferrer' href={props.url+ 'public/subscription_files/Fynamics GSP for Einvoice.pdf'}>Read More</a></p> : <></>}
                            
                            { isSubTabEnabled === true ? saveBtnDisabled === true ? (<BootstrapSwitchButton disabled checked={(einvoice_status === '1' )} onlabel='Active' offlabel='Inactive' onstyle="success" offstyle="danger" size="sm" width={100}  onChange={(checked: boolean) => { subscriptionStatus(checked); }} />) : '' : <></>}
                            
                            {einvPlan ? <>
                                {isSubTabEnabled === true ? saveBtnDisabled === false ? (<BootstrapSwitchButton checked={einvValidateBlock || (einvoice_status === '1')} onlabel='Active' offlabel='Inactive' onstyle="success" offstyle="danger" size="sm" width={100}  onChange={(checked: boolean) => {/* setValidateFlag('einv'); */setEinvValidateBlock(checked);updateEinvStatus(checked)}} />) : '' : <></>}
                            </> : <>
                                {isSubTabEnabled === true ? saveBtnDisabled === false ? (<BootstrapSwitchButton checked={einvValidateBlock || (einvoice_status === '1')} onlabel='Active' offlabel='Inactive' onstyle="success" disabled offstyle="danger" size="sm" width={100}  onChange={(checked: boolean) => {/* setValidateFlag('einv'); */setEinvValidateBlock(checked);updateEinvStatus(checked)}} />) : '' : <></>}
                            </>}
                            {}

                        </div>
                        <div className='col-sm-2'></div>
                        <div className={einvValidateBlock === true || einvoice_status === '1' ? "col-sm-3 " : "d-none"}>
                            <form id="form-einv" onSubmit={handleValidationEinv} autoComplete="off">
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="bs-validation-name">API Username</label>
                                    {einvUser === '' ?
                                    <input type="text" className="form-control form-control-sm" name='bs-validation-name' id="bs-validation-name" value={einvNicUser || einvUser} onChange={(e) => setEinvNicUser(e.target.value)}/>
                                    :
                                    <input type="text" className="form-control form-control-sm" name='bs-validation-name' readOnly={ (einvoice_status === '1')} id="bs-validation-name" value={einvNicUser || einvUser} onChange={(e) => setEinvNicUser(e.target.value)}/>
                                    }
                                </div>
                                <div className="mb-3 form-password-toggle">
                                    <label className="form-label" htmlFor="bs-validation-password">API Password</label>
                                    <div className="input-group input-group-merge">
                                        {einvPass === '' ?
                                        <input type="text" id="bs-validation-password" name='bs-validation-password' className="form-control form-control-sm" value={einvNicPassword || einvPass} onChange={(e) => setEinvNicPassword(e.target.value)}/>
                                        :    
                                        <input type="text" id="bs-validation-password" name='bs-validation-password' readOnly={ (einvoice_status === '1')} className="form-control form-control-sm" value={einvNicPassword || einvPass} onChange={(e) => setEinvNicPassword(e.target.value)}/>
                                        }
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                    {einvUser === '' && einvPass === '' ?
                                    <button type="submit" className="btn btn-primary btn-sm">Save</button>
                                    :
                                    <button type="submit" disabled={ (einvoice_status === '1')} className="btn btn-primary btn-sm">Save</button>
                                        }
                                    </div>
                                </div>
                            </form> 
                        </div>
                    </div>
                    <hr/>
                    <div className='row'>
                        <div className="col-sm-4">
                            <h3 style={{'fontWeight': '300', 'color': '#566a7f'}}>Eway Bill</h3>
                            {isSubTabEnabled === true ? <p className="price">Same e-Invoice credentials can be used here</p> : <></>}
                            {isSubTabEnabled === true ? <p className="price">For non-einvoice enabled profiles <a target='_blank' rel="noreferrer" href={props.url + 'public/subscription_files/Fynamics GSP for EWay bill.pdf'}>Read More</a></p>: <></>}
                            { isSubTabEnabled === true ? saveBtnDisabled === true ? (<BootstrapSwitchButton disabled checked={(ewayStatus === '1' )} onlabel='Active' offlabel='Inactive' onstyle="success" offstyle="danger" size="sm" width={100}  onChange={(checked: boolean) => { subscriptionStatus(checked); }} />) : '' : <></>}
                            
                            {ewayPlan ? <>
                                { isSubTabEnabled === true ? saveBtnDisabled === false ?  (<BootstrapSwitchButton  checked={ewayValidateBlock || (ewayStatus === '1')} onlabel='Active' offlabel='Inactive' onstyle="success" offstyle="danger" size="sm" width={100}  onChange={(checked: boolean) => {/* setValidateFlag('eway'); */setEwayValidateBlock(checked); updateEwbStatus(checked)}} />) : '' : <></>}
                            
                            </> : <>
                                { isSubTabEnabled === true ? saveBtnDisabled === false ?  (<BootstrapSwitchButton  checked={ewayValidateBlock || (ewayStatus === '1')} onlabel='Active' offlabel='Inactive' onstyle="success" disabled offstyle="danger" size="sm" width={100}  onChange={(checked: boolean) => {/* setValidateFlag('eway'); */setEwayValidateBlock(checked); updateEwbStatus(checked)}} />) : '' : <></>}
                            </>}
                        </div>
                        <div className='col-sm-2'></div>
                        <div className={ewayValidateBlock === true || ewayStatus === '1' ? "col-sm-3 " : "d-none"}>
                            {/*  */}
                        <form id="form-eway" onSubmit={handleValidationEway}  autoComplete="off">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="bs-validation-name-eway">API Username</label>
                                {einvUser === '' && einvPass === '' ? 
                                <input type="text" className="form-control form-control-sm" name='bs-validation-name-eway' id="bs-validation-name-eway" value={ewayNicUser} onChange={(e) => {setEwayNicUser(e.target.value)}}/>
                                :
                                <input type="text" className="form-control form-control-sm" readOnly={(einvoice_status === '1') || (ewayStatus === '1')} name='bs-validation-name-eway' id="bs-validation-name-eway" value={ewayNicUser || einvUser} onChange={(e) => {setEwayNicUser(e.target.value)}}/>
                            }
                            </div>
                            <div className="mb-3 form-password-toggle">
                                <label className="form-label" htmlFor="bs-validation-password-eway">API Password</label>
                                <div className="input-group input-group-merge">
                                    {einvUser === '' && einvPass === '' ?
                                    <input type="text" id="bs-validation-password-eway" name='bs-validation-password-eway' className="form-control form-control-sm" value={ewayNicPassword} onChange={(e) => setEwayNicPassword(e.target.value)}/>
                                    :
                                    <input type="text" id="bs-validation-password-eway" readOnly={(einvoice_status === '1') || (ewayStatus === '1')} name='bs-validation-password-eway' className="form-control form-control-sm" value={einvPass} onChange={(e) => setEwayNicPassword(e.target.value)}/>
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                {einvUser === '' && einvPass === '' ?
                                <button type="submit" className="btn btn-primary btn-sm">Save</button>
                                :
                                <button type="submit" disabled={true} className="btn btn-primary btn-sm">Save</button>
                                }
                                </div>
                            </div>
                        </form> 
                        </div>
                    </div>
                    <hr/>
                    <div className='row'>
                        <div className="col-sm-4">
                            <h3 style={{'fontWeight': '300', 'color': '#566a7f'}}>GST returns filing</h3>
                            {isSubTabEnabled === true ? <p className="price">Enable API on GSTN for GSP</p> : <></>}
                            {isSubTabEnabled === true ? saveBtnDisabled === true ? (<BootstrapSwitchButton disabled checked={(gstStatus === '1' )} onlabel='Active' offlabel='Inactive' onstyle="success" offstyle="danger" size="sm" width={100}  onChange={(checked: boolean) => { subscriptionStatus(checked); }} />) : '' : <></>}
                            {gstPlan ? <>
                                {isSubTabEnabled === true ? saveBtnDisabled === false ? (<BootstrapSwitchButton  checked={gstinValidateBlock || (gstStatus === '1')} onlabel='Active' offlabel='Inactive' onstyle="success" offstyle="danger" size="sm" width={100}  onChange={(checked: boolean) => {/* setValidateFlag('gst'); */setGstinValidateBlock(checked);updateGstStatus(checked)}} />) : '' : <></>}
                            </> : <>
                                {isSubTabEnabled === true ? saveBtnDisabled === false ? (<BootstrapSwitchButton  checked={gstinValidateBlock || (gstStatus === '1')} onlabel='Active' offlabel='Inactive' onstyle="success" disabled offstyle="danger" size="sm" width={100}  onChange={(checked: boolean) => {/* setValidateFlag('gst'); */setGstinValidateBlock(checked);updateGstStatus(checked)}} />) : '' : <></>}
                            </>}
                        </div>
                        <div className='col-sm-2'></div>
                        <div className={gstinValidateBlock === true || gstStatus === '1' ? "col-sm-3 " : "d-none"}>
                        <form id="form-gst" onSubmit={handleValidationGst} autoComplete='off'>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="bs-validation-name-gst">API Username</label>
                                {gstUser === '' ?
                                <input type="text" className="form-control form-control-sm" name='bs-validation-name-gst' id="bs-validation-name-gst" value={gstNicUser || gstUser} onChange={(e) => setGstNicUser(e.target.value)}/>
                                :
                                <input type="text" className="form-control form-control-sm" readOnly={(gstStatus === '1')} name='bs-validation-name-gst' id="bs-validation-name-gst" value={gstNicUser || gstUser} onChange={(e) => setGstNicUser(e.target.value)}/>
                                }
                            </div>
                            <div className="row">
                                <div className="col-12">
                                {gstUser === '' ?
                                <button type="submit" className="btn btn-primary btn-sm">Save</button>
                                :
                                <button disabled={true} type="submit" className="btn btn-primary btn-sm">Save</button>
                            }
                                </div>
                            </div>
                        </form> 
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal {...props} centered show={youtubeModal} onHide={handleYoutubeViewClose} size="md" aria-labelledby="whatsnew-modal" backdrop="static" keyboard={false}>
			<Modal.Header>
				<Modal.Title>Welcome to FynOne</Modal.Title>
			</Modal.Header>
			<Modal.Body>
			Hey There!! Welcome to FynOne - the Billing & Accounting app for Bharat<br/><br/>FynOne is feature loaded with deep focus on Compliance, Collaboration & Simplicity. To have a quick walkthrough of the features, visit the video link below that's hosted on Youtube. 
			</Modal.Body>
			<Modal.Footer>
				<a className='btn btn-primary' href='https://youtu.be/uFOhnS8QuEA' target='_blank' rel='noreferrer'>Watch</a>
				<button type='button' className='btn btn-outline-secondary' onClick={handleClick}>Skip</button>
			</Modal.Footer>
		</Modal>
        </div>
    );
}