import React, { useState,useEffect } from 'react';
import Select from "react-select";
import * as Common from '../common/ImportUserEssentials';
import { QRCode } from 'react-qrcode-logo';
import Button from 'react-bootstrap/Button';

export default function AddMart(props){
	let maxLenName = 30;
	const [nameCharCntDiv, setNameCharCntDiv] = useState(false);
	const [nameCharCnt, setNameCharCnt] 	  = useState('0');
	const [mart_name, setMartName]     = useState('');
	const [mart_type,setMartType] = useState('');
	const [mart_id,setMartId] = useState(0);
	const [mart_visibility,setMartVisibility] = useState('public');
	const [mart_uid,setMartUid] = useState('');
	const [martTypes,setMartTypes] = useState([]);
	const [selectLoaded, setSelectLoaded] = useState(false);
	const [waLink,setwaLink] = useState('');
	const [copyLink,setCopyLink] = useState('');
	const [invoice_prefix, setInvoicePrefix] 	    = useState('');
	const [invoicePrefixData, setInvoicePrefixData] = useState('');
	const [invoiceCounterVal, setInvoiceCounterVal] = useState(1);
	const [invoiceCountData, setInvoiceCountData]   = useState('');
	const [sample_invoice,setSampleInvoice]         = useState('');
	const [home_delivery,setHomeDelivery]         	= useState('N');
	const [pick_up,setPickUp]         				= useState('N');
	const [reservation,setReservation]         		= useState('N');
	const qrref = React.useRef();
	const navigate = Common.useNavigate();

	useEffect(() => {
		document.title = (( props.param === 'add' ) ? 'Add Mart' : 'Mart Settings')+' | '+props.site_name;
		//Load mart types
		Common.axios({
			method: 'get',
			url:props.url+'mart/types',
		}).then(function (response) {
			if( response.data.success ){
				setMartTypes(response.data.data);
				return false;
			}
			Common.toast.error(response.data.message);
		}).catch(error => console.log(error));
		Common.axios({
			method: 'post',
			url:props.url+'user/profileDetails',
			data: {pid:Common.AES.encrypt(localStorage.getItem('fo_profileid'), props.site_key).toString()}
		}).then(function (response) {
			if(response.data.success ){
				if( response.data.data !== null ){
					setInvoiceCounterVal(response.data.data.invoice_counter_value === '0' ? 1:response.data.data.invoice_counter_value);
					setInvoiceCountData(response.data.data.invoice_counter_value);
					if(response.data.data.prefix === ''){
						setPrefixInput(Common.acceptOnlyCharsNumbersNoSpace(response.data.data.name).substring(0,3));
						return false;
					}else{
						setInvoicePrefixData(response.data.data.prefix);
						setInvoicePrefix(response.data.data.prefix);
						setPrefixInput(response.data.data.prefix);
					}
				}
				return false;
			}
			Common.toast.error(response.data.message);
		}).catch(error => console.log(error));
		const setPrefixInput = (val) => {
			if( val.length <= 4 ){
				Common.axios({
					method: 'get',
					url: props.url+'ajax/sampleInvoice/'+val,
				}).then(function (response) {
					if(response.data.success){
						setSampleInvoice(response.data.data);
						return false;
					}
				}).catch(error => console.log(error));
			}
			setSampleInvoice('');
		}
		if( props.param === 'add' ){
			setSelectLoaded(true);
			//Add mart
			Common.axios({
				method: 'post',
				url:props.url+'mart/preAdd',
				data: {'pid':Common.AES.encrypt(localStorage.getItem('fo_profileid'), props.site_key).toString()},
			}).then(function (response) {
				if( response.data.success ){
					if( response.data.data.exist ){
						navigate('/mart');
						return false;
					}
					setMartNamePlus(response.data.data.default+"'s Digital Store");
					return false;
				}
				Common.toast.error(response.data.message);
			}).catch(error => console.log(error));
		}
		if( props.param === 'settings' ){
			Common.axios({
				method: 'post',
				url:props.url+'mart/details',
				data: {'pid':Common.AES.encrypt(localStorage.getItem('fo_profileid'), props.site_key).toString()},
			}).then(function (response) {
				if( response.data.success ){
					setMartNamePlus(response.data.data.name);
					setMartType({label:response.data.data.sub,value:response.data.data.type});
					setMartId(response.data.data.id);
					setMartVisibility(response.data.data.visibility);
					setMartUid(response.data.data.uid);
					setSelectLoaded(true);
					getShareLink(response.data.data.name,response.data.data.uid);
					setHomeDelivery(response.data.data.home_delivery_service);
					setPickUp(response.data.data.pick_up_service);
					setReservation(response.data.data.reservation_service);
					return false;
				}
				Common.toast.error(response.data.message);
			}).catch(error => console.log(error));
		}
	}, [props]);
	const getShareLink = (name,uid) =>{
		Common.axios({
			method: 'post',
			url:props.url+'mart/shareLink',
			data: {'host':Common.AES.encrypt(props.martlink, props.site_key).toString(),'uid':Common.AES.encrypt(uid, props.site_key).toString(),'mart_name':Common.AES.encrypt(name, props.site_key).toString()},
		}).then(function (response) {
			if( response.data.success ){
				setwaLink(response.data.data);
				setCopyLink(response.data.copy_link);
				return false;
			}
		}).catch(error => console.log(error));
	}
	const groupStyles = {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	};
	const formatGroupLabel = (data: GroupedOption) => (
		  <div style={groupStyles}>
			<span>{data.label}</span>
			<span className="badge bg-primary">{data.options.length}</span>
		  </div>
		);
	const setMartNamePlus = (val) => {
		setMartName(val);
		setNameCharCnt(val.length);
	}
	const handleSubmit = (e) => {
		e.preventDefault();
        let formData = new FormData(e.target);
		let data = {};

        // convert the key/value pairs
		for (var [key, value] of formData.entries()) {
			if( key === 'mart_name' && value === '' ){
				Common.toast.error('Name cannot be left blank.');
				return false;
			}
			if( key === 'mart_type' && value === '' ){
				Common.toast.error('Type cannot be left blank.');
				return false;
			}
			data[key] = Common.AES.encrypt(value, props.site_key).toString();
		}
        data['enable_auth_sign'] = true;
        if(invoiceCounterVal === '' || invoiceCounterVal === '0'){
            Common.toast.error('Invoice counter value cannot be blank or 0.');
            return false;
        } else if (invoice_prefix.length === 2 && invoiceCounterVal.length > 6) {
            Common.toast.error('Invoice length cannot be more than 16 character');
            return false;
        } else if (invoice_prefix.length === 3 && invoiceCounterVal.length > 5) {
            Common.toast.error('Invoice length cannot be more than 16 character');
            return false;
        } else if (invoice_prefix.length === 4 && invoiceCounterVal.length > 4) {
            Common.toast.error('Invoice length cannot be more than 16 character');
            return false;
        }
		if(home_delivery === "N" && pick_up === "N" && reservation === "N"){
            Common.toast.error('Select any one service');
            return false;
		}

		data['pid'] = Common.AES.encrypt(localStorage.getItem('fo_profileid'), props.site_key).toString();
		data['param'] = Common.AES.encrypt(props.param,props.site_key).toString();
		data['mart_id'] = Common.AES.encrypt(mart_id,props.site_key).toString();
		data['mart_uid'] = Common.AES.encrypt(mart_uid,props.site_key).toString();
		data['home_delivery'] = Common.AES.encrypt(home_delivery,props.site_key).toString();
		data['pick_up'] = Common.AES.encrypt(pick_up,props.site_key).toString();
		data['reservation'] = Common.AES.encrypt(reservation,props.site_key).toString();
		Common.showLoader(true);
		Common.axios({
            method: 'post',
            url:props.url+'mart/add',
            data: data,
        }).then(function (response) {
			if(response.data.success){
				Common.toast.success(response.data.message);
				setTimeout(() => {
					navigate("/mart-orders");
					Common.showLoader(false);
				}, 1000);
				return false;
            }
			Common.showLoader(false);
			Common.toast.error(response.data.message);
        }).catch(error => console.log(error));
	}
	const generateQR = () => {
		Common.showLoader(true);
		Common.axios({
            method: 'post',
            url:props.url+'mart/generateQR',
            data: {'martid':Common.AES.encrypt(mart_id, props.site_key).toString()},
        }).then(function (response) {
			Common.showLoader(false);
			if(response.data.success){
				setMartUid(response.data.data);
				getShareLink(mart_name,response.data.data);
				Common.toast.success(response.data.message);
				return false;
            }
			Common.toast.error(response.data.message);
        }).catch(error => console.log(error));
	}
	const updateService = (event,type) => {
		if(type === "home_delivery"){
			setHomeDelivery(event.target.checked ? 'Y' : 'N');
		}
		else if(type === "pick_up"){
			setPickUp(event.target.checked ? 'Y' : 'N');
		}
		else{
			setReservation(event.target.checked ? 'Y' : 'N');
		}
	}
	const downloadQRCode = () => {
		qrref.current?.download('png',mart_name.replace(/[^a-zA-Z ]/g, "")+'_QR')
	}
	const copyBtnClickCallback = function(e) {
        navigator.clipboard.writeText(copyLink);
        Common.toast.success('Copied');
        e.target.focus();
    };
	/* const copyMartLink = () => {
		navigator.clipboard.writeText(props.martlink+'approve/'+mart_uid);
		Common.toast.success('Copied to clipboard.');
	} */
    return (
		<div className="container-xxl flex-grow-1 container-p-y">
			<div className="text-center">
				<a href="/mart">
					<img alt="FYNMART Merchant Logo" height="70" width="auto" src={props.site_logo}></img>
				</a>
			</div>
			<h4 className="fw-bold py-1 text-center"><span className="text-muted fw-light"></span>{props.param === 'add' ? 'Add Mart':'Mart Settings'}
				<span className="dropdown">
					<a href="https://youtu.be/2563tPWJvVg" target="_blank" rel="noreferrer"><i className='text-danger bx bxl-youtube bx-md'></i></a>
				</span>
			</h4>
			<div className="row justify-content-center">
				<div className="col-sm-4">
					<div className="mb-3">
						<div className="card h-100">
							<div className="card-body">
								<form id="martaddForm" onSubmit={handleSubmit} autoComplete="off">
									<div className="mb-3">
										<label className="form-label" htmlFor="mart-name">Mart Name<span className="text-danger">*</span></label>
										<input type="text" maxLength={maxLenName} className="form-control" id="mart-name" name="mart_name" placeholder="Mart Name" aria-label="Mart Name" required autoFocus value={mart_name}
											onFocus={(e) => setNameCharCntDiv(true)}
											onBlur={(e) => setNameCharCntDiv(false)}
											onChange={(e) => setMartNamePlus(e.target.value)}
										/>
										{ nameCharCntDiv && <><span className="input_char_count">{nameCharCnt}/{maxLenName}</span></>}
									</div>
									<div className="mb-3">
										<label htmlFor="mart-type" className="form-label">Mart Type<span className="text-danger">*</span></label>
										{selectLoaded && <>
										<Select name="mart_type" id="mart-type"
										defaultValue={mart_type}
										placeholder="Select Type" options={martTypes} formatGroupLabel={formatGroupLabel}/>
										</>}
									</div>
									<div className="mb-3">
										<label className="form-label" htmlFor="invoice-prefix">Invoice Prefix<span className="text-danger">*</span></label>
										<input type="text" id="invoice-prefix" name="invoice_prefix" className="form-control text-uppercase" maxLength="4" placeholder="Prefix (2-4) chars" aria-label="Prefix" aria-describedby="Prefix" value={invoice_prefix} onChange={(e) => setInvoicePrefix(Common.acceptOnlyCharsNumbersNoSpace(e.target.value))} required disabled={invoicePrefixData?true:false}/>
										<div className="form-text">Sample Invoice: {sample_invoice}</div>
									</div>
									<div className="mb-3">
										<label className="form-label" htmlFor="invoice-counter-value">Invoice Counter Value<span className="text-danger">*</span></label>
										<input type="text" id="invoice-counter-value" name="invoice_counter_value" className="form-control text-uppercase"  placeholder="Invoice Counter Value" aria-label="Counter Value" aria-describedby="Counter Value" value={invoiceCounterVal} onChange={(e) => setInvoiceCounterVal(Common.acceptOnlyDecimal(e.target.value))} required disabled={invoiceCountData !== '0'?true:false}/>
									</div>
									<div className="mb-3">
										<label className="form-label">Service Options</label>
										<div className="row">
											<div className="col-8">
												<small>Home Delivery/Service</small>
											</div>
											<div className="col-4">
												<div className="form-check form-switch">
													<input className="form-check-input" type="checkbox" role="switch" name="home_delivery" checked={home_delivery === "Y"?true:false} onChange={(e)=>{updateService(e,"home_delivery")}}/>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-8">
												<small>Pick up</small>
											</div>
											<div className="col-4">
												<div className="form-check form-switch">
													<input className="form-check-input" type="checkbox" role="switch" name="pick_up" checked={pick_up === "Y"?true:false} onChange={(e)=>{updateService(e,"pick_up")}}/>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-8">
												<small>Reservation</small>
											</div>
											<div className="col-4">
												<div className="form-check form-switch">
													<input className="form-check-input" type="checkbox" role="switch" name="reservation" checked={reservation === "Y"?true:false} onChange={(e)=>{updateService(e,"reservation")}}/>
												</div>
											</div>
										</div>
									</div>
									<div className="mb-3">
										<label htmlFor="mart-visibility" className="form-label">Visibility<span className="text-danger">*</span></label>
										<div>
											<div className="form-check form-check-inline">
												<input className="form-check-input" type="radio" name="visibility" id="mart-public" value="public" onChange={(e) => setMartVisibility(e.target.value)} checked={mart_visibility==='public' ? true:false}/>
												<label className="form-check-label" htmlFor="mart-public">Public&nbsp;</label>
												<Common.OverlayTrigger overlay={Common.popoverContent('','Customers can scan your QR code and get access to your store to Select, Order, Pay & Track')}><i className='bx bxs-info-circle bx-tada'></i></Common.OverlayTrigger>
											</div>
											<div className="form-check form-check-inline">
												<input className="form-check-input" type="radio" name="visibility" id="mart-private" value="private" onChange={(e) => setMartVisibility(e.target.value)} checked={mart_visibility==='private' ? true:false} disabled={localStorage.getItem('fo_subplan') === 'Free' ? true : false}/>
												<label className="form-check-label" htmlFor="mart-private">Private&nbsp;</label>
												<Common.OverlayTrigger overlay={Common.popoverContent('','Simple POS (Point of Sale solution) where only you can manage orders without any access to your customers.')}><i className='bx bxs-info-circle bx-tada'></i></Common.OverlayTrigger>
											</div>
											{localStorage.getItem('fo_subplan') === 'Free' && <>
												<br></br>
												<small className='text-danger'>Note: Private visibility is disabled in Free Plan.</small>
											</>}
										</div>
									</div>
									{mart_visibility === 'public' &&
									<div className="mb-3">
										<input type="hidden" name="uid" value={mart_uid}/>
										<div className="text-center pb-3">
											{mart_uid === '' ?
												<Button variant="success" size="sm" onClick={generateQR}><i className='bx bx-qr'></i> Generate QR</Button>
											:
											<>
											<input type="hidden" name="uid" value={mart_uid}/>
											<div className="mb-2" style={{ background:"white",height: "auto", margin: "0 auto", width: "80%" }}>
											  <QRCode ref={qrref}
												enableCORS={true}
												size={256}
												logoImage={'assets/img/mart_favicon.png'}
												value={props.martlink+'approve/'+mart_uid}
												viewBox={`0 0 256 256`}
											  />
											</div>
											<div className="mb-3 bg-warning rounded"><small className="text-white">Free 500 credits will be automatically added for Public Marts on confirming 1st pre-order from customer.</small></div>
											<div className="">
												<Button variant="info" size="sm" onClick={downloadQRCode} className="me-1"><i className='bx bx-download'></i> <span className="hidden-phone">Download QR</span></Button>
												<a href={waLink} target="_blank" rel="noreferrer" className="btn btn-success btn-sm ms-1 text-white"><i className='bx bxl-whatsapp' ></i> <span className="hidden-phone">Share Link</span></a>
												<button type="button" id="copy" onClick={copyBtnClickCallback} className="btn btn-sm btn-secondary ms-2 text-white"><i className='bx bx-copy'></i> <span className="hidden-phone">Copy URL</span></button>
											</div>
											</>
											}
										</div>
									</div>
									}
									<div className="my-3 text-center">
										{props.param !== 'add' && <>
											<a href="/mart" role="button" className="me-lg-2 btn btn-sm btn-info"><i className='bx bx-arrow-back'></i>Back</a>
										</>}
										<button type="submit" className="btn btn-sm btn-primary" ><span className="tf-icons bx bx-save"></span>&nbsp;Save</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Common.FYNTicket url={props.url}/>
		</div>
    );

}