const doc_types = [
	{
		label: 'Quotation',
		value: 'QUT'
	},
	{
		label: 'Estimate',
		value: 'EST'
	},
	{
		label: 'Proforma Invoice',
		value: 'PIN'
	},
	{
		label: 'Credit Bill',
		value: 'CRB'
	},
	{
		label: 'Proposal',
		value: 'PRO'
	}
];
export default doc_types;