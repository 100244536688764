import React  from "react";
import Layout from './MartLayout';
import NavBar from './MartNavBar';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Select from "react-select";
import * as Common from '../common/ImportUserEssentials';
import * as DTE from '../common/ImportDataTableEssentials';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { confirmAlert } from 'react-confirm-alert';
import styled from 'styled-components';
//import { useReactToPrint } from 'react-to-print';
import '../styles/mart-print.css';
import moment from 'moment';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { AsyncTypeahead,Highlighter } from 'react-bootstrap-typeahead';
import {Html5Qrcode} from "html5-qrcode";
import beepSound from '../sounds/scanner-beep.mp3';

const Tab = styled.button`
	font-size: 16px;
	padding: 10px 60px;
	cursor: pointer;
	opacity: 0.6;
	background: white;
	border: 0;
	outline: 0;
	background-color: #ecf0f1f2;
	${({ active }) =>
		active &&
		`
		border-bottom: 2px solid black;
		opacity: 1;
	`}
`;
const ButtonGroup = styled.div`
	display: flex;
`;
export default function MartDashboard(props){
	const [DTpending, setDTPending] = React.useState(true);
	const [dt_load,setDtLoad] = React.useState(true);
	const [sortOrder,setSortOrder] = React.useState('');
	const [filterText, setFilterText] = React.useState('');
	const [refWalletToken,setRefWalletToken] = React.useState(true);
	//const componentRef = useRef();

	const [totalDataCnt,setTotalDataCount] = React.useState({'PreOrder':'','Active':'','Completed':'','Cancelled':''});	
    const martTabTypes = ['PreOrder','Active', 'Completed', 'Cancelled'];
    const [activeTab, setActCan] = React.useState(martTabTypes[1]);
	const [saleOrdersCancelShow,setSaleOrdersCancelShow] = React.useState(false);
	const [saleOrdersCancelRemarks,setSaleOrdersCancelRemarks] = React.useState('');
	const [saleOrdersId,setSaleOrdersId] = React.useState('');
	const [invNo,setInvNo] = React.useState('');
	//const [itemCount,setItemCount] = React.useState(0);
	
	const [orders, setOrdersList]    = React.useState([]);
	const [totalRows, setTotalRows] = React.useState(0);
	const [perPage, setPerPage] = React.useState(10);
	//const [invoiceData, setInvoiceData]    = React.useState([]);
	//const [invItemData, setInvItemData]    = React.useState([]);
	//const [paymentData, setPaymentData]    = React.useState([]);
	const [dates, setDates] = React.useState({
		start: moment().startOf('month'),
		end: moment().endOf('month'),
    });
	const { start, end } = dates;
	const label = start.format('DD-MM-YYYY') + ' ~ ' + end.format('DD-MM-YYYY');
	const setDatePicker = (start, end) => {
		setDates({ start, end });
		setDtLoad(!dt_load);
	}	
    const navigate = Common.useNavigate();
	
	const [showCart, setShowCart] = React.useState(false);
	const [cartOtherCharge,setCartOtherCharge] = React.useState('');
	const [cartRoundoff,setCartRoundoff] = React.useState('');
	const [cartAddDiscount,setCartAddDiscount] = React.useState('');
	// const [totalInvoiceValue,setTotalInvoiceValue] = React.useState(0);
	const [invoiceDetails,setInvoiceDetails] = React.useState('');
	// const [itemDetails,setItemDetails] = React.useState([]);
	const [martCartMode,setMartCartMode] = React.useState('normal');
	const filterBy = () => true;
	const [isItemLoading, setIsItemLoading] = React.useState(false);
	const [itemsCart,setItemsCart] = React.useState([]);
	const [cartItems,setCartItems] = React.useState([]);
	const [cartSubTotal, setCartSubTotal] = React.useState(0);
	const [cartGst, setCartGst] = React.useState(0);
	const [reCalcCart,setReCalcCart] = React.useState(true);
	const [cartTotal, setCartTotal] = React.useState(0);
	const [cust_details, setCustDetails] = React.useState({'id':0,'name':'','gstin':'','phone':''});
	const [defaultPayee,setDefaultPayee] = React.useState(0);
	const [soid,setSoid] = React.useState('');

	const [martData,setMartData] = React.useState([]);
	const [martServiceType,setMartServiceType] = React.useState('');
	const [newAddressModal,setNewAddressModal] = React.useState(false);
	const [flat_number,setFlatNumber] = React.useState('');
	const [street_name,setStreetName] = React.useState('');
	const [landmark,setLandmark] = React.useState('');
	const [pincode,setPincode] = React.useState('');
	const [newAddress,setNewAddress] = React.useState([]);
	const [address_id,setAddressId] = React.useState(0);
	const [edit_address_id,setEditAddressId] = React.useState(0);
	const [address_action,setAddressAction] = React.useState(0);
	const [martAddressIndex, setMartAddressIndex] = React.useState(0);
	const [addressDetails, setAddressDetails]    = React.useState('');
	const [displayAddressModel, setDisplayAddressModel]    = React.useState(false);

    React.useEffect(() => {
        document.title = 'Orders | '+props.site_name;
		loadDataTable(1,perPage,filterText,dates,sortOrder);
	}, [props,perPage,dt_load]);
	// React.useEffect(() => {
	// 	let total = parseFloat(invoiceDetails.total_amt);
	// 	if( cartOtherCharge !== '' ){
	// 		total += parseFloat(cartOtherCharge);
	// 	}
	// 	if( cartRoundoff !== '' ){
	// 		total += parseFloat(cartRoundoff);
	// 	}
	// 	if( cartAddDiscount !== '' ){
	// 		total -= parseFloat(cartAddDiscount);
	// 	}
    //     setTotalInvoiceValue(total);
		
	// }, [invoiceDetails,cartOtherCharge,cartRoundoff,cartAddDiscount]);
	React.useEffect(() => {
		Common.axios({
			method: 'get',
			url:props.url+'bank-accounts/defaultPayee/'+localStorage.getItem('fo_profileid'),
		}).then(function (response) {
			if(response.data.success){
				setDefaultPayee(response.data.data.value);
			}
		}).catch(error => console.log(error));
	}, [props]);
	React.useEffect(() => {
		let cart_sub = 0, cart_gst = 0;
		if( cartItems.length > 0 ){
			cartItems.forEach(item => {
				cart_sub += Number(item.qty) * Number(item.actual_sale_price);
				cart_gst += Number(item.qty) * Number(item.gst_amt);
			});
		}
		setCartSubTotal(cart_sub);
		setCartGst(cart_gst);
		let total_amount = (Number(cart_gst) + Number(cart_sub) + Number(cartOtherCharge) - Number(cartAddDiscount)) + (Number(cartRoundoff));
		setCartTotal(total_amount.toFixed(2));
	}, [cartItems,reCalcCart]);

	//Barcode scanner
	let audio = new Audio(beepSound);
	const playBeep = () => {
		if(audio.paused === true){
		  audio.play();
		}else{
		  audio.pause();
		  audio.currentTime = 0;
		  audio.play();
		}
	};
	React.useEffect(() => {
		if( document.getElementById('barCartReader') !== null ){
			const html5QrCode = new Html5Qrcode("barCartReader");
			const startScanner = async () => {
				await html5QrCode.start(
					{ facingMode: 'environment' },
					{
						fps: 10,
						qrbox: { width: 250, height: 180 }
						//qrbox: qrboxFunction
					},
					(decodedText, decodedResult) => {
						if(decodedText !== ''){
							playBeep();
							//stopScanner();
							setMartCartMode('normal');
							addItemBarcode(decodedText);
						}
					},
					(errorMessage) => {
						// parse error, ignore it.
					}
				).catch((err) => {
					Common.toast.error(err);
					setMartCartMode('normal');
				});
			};
			const addItemBarcode = (barcode) => {
				Common.showLoader(true);
				Common.axios({
					method: 'post',
					url:props.url+'martorders/itemFromBarcode',
					data: {martid:Common.secureLocalStorage.getItem('fo_martid'),barcode:barcode}
				}).then(function (response) {
					Common.showLoader(false);
					//startScanner();
					setMartCartMode('barcode');
					if(response.data.success){
						addToCart(response.data.data);
						Common.toast.success(response.data.message);
						return false;
					}
					Common.toast.error(response.data.message);
				}).catch(error => console.log(error));
			}
			const stopScanner = async () => {
				if (html5QrCode.isScanning) {
					await html5QrCode.stop();
					html5QrCode.clear();
				}
			};
			if( martCartMode === 'barcode' ){
				startScanner();
			}
			return () => {
				stopScanner();
			};
		}
	}, [martCartMode]);
	const handleDocRoundoff = (roundoff) => {
		if((Number(0.99) < Number(roundoff)) || (Number(-0.99) > Number(roundoff))){
			Common.toast.error('Round off amount should be between -0.99 to 0.99');
			return false;
		}
		setCartRoundoff(roundoff);
		finalDocTotalCal(cartOtherCharge,roundoff,cartAddDiscount);
	}
	const loadDataTable = (page,perPage,filterText,dates,sort='',active_tab=activeTab) => {
		Common.axios({
            method: 'post',
            url:props.url+'martorders/listing',
			data: {martid:Common.secureLocalStorage.getItem('fo_martid'),'page':page,'perPage':perPage,'sort':sort,'search':filterText,'dates':dates,'active_tab':active_tab}
        }).then(function (response) {
            if(response.data.success){
				setOrdersList(response.data.data);
				setTotalRows(response.data.total);
				// setTotalDataCount(response.data.total);
				setTotalDataCount(prevState => ({
				...prevState,
					[active_tab]: response.data.total
				}));
				setDTPending(false);
				return false;
            }
			Common.toast.error(response.data.message);
        }).catch(error => console.log(error));
	}
	const handleClear = () => {
		if (filterText) {
			setDtLoad(!dt_load);
			setFilterText('');
		}
	};
	const handlePageChange = page => {
		loadDataTable(page,perPage,filterText,dates,sortOrder);
	};
	const handlePerRowsChange = async (newPerPage, page) => {
		setPerPage(newPerPage);
	};
	const handleSort = (column, sortDirection) => {
		setSortOrder(btoa(column.sortField+'####'+sortDirection));
		loadDataTable(1,perPage,filterText,dates,btoa(column.sortField+'####'+sortDirection));
	};
	const itemsSearch = (query: string) => {
		setIsItemLoading(true);

		fetch(props.url+`martorders/itemSearch/`+Common.secureLocalStorage.getItem('fo_martid')+'/'+query)
			.then((resp) => resp.json())
			.then(({ items }: Response) => {
			setItemsCart(items);
			setIsItemLoading(false);
		});
	};
	const addToCart = (item) => {
		if( presentInCart(item.iid) ){
			incrementCart(item);
			return false;
		}
		//item['qty'] = item['service'] === '0' ? item['min_qty'] : Common.displayUnit(1);
		item['qty'] = 1;
		item['actual_sale_price'] = item['sale_price'] - item['discount'];
		setCartItems([...cartItems,item]);
	}
	const presentInCart = (iid) => {
		return cartItems.some(item => iid === item.iid);
	}
	const incrementCart = (item) => {
		const index = cartItems.findIndex(cart => cart.iid === item.iid);
		let newQty = parseFloat(cartItems[index]['qty']) + 1;
		updateCart(index,newQty);
	}
	const updateCart = (index,newQty) => {
		newQty = parseFloat(newQty) > 0 ? newQty.toString().match(/^-?\d+(?:\.\d{0,3})?/)[0] : newQty;
		let temp_cart = cartItems;
		temp_cart[index]['qty'] = newQty;
		setCartItems(temp_cart);
		setReCalcCart(!reCalcCart);
	}
	const updateItemPrice = (index,newPrice) => {
		newPrice = parseFloat(newPrice) > 0 ? newPrice.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0] : newPrice;
		let temp_cart = cartItems;
		temp_cart[index]['actual_sale_price'] = newPrice;
		temp_cart[index]['gst_amt'] = Number(temp_cart[index]['qty'])*Number(temp_cart[index]['gst_rt'])*Number(temp_cart[index]['actual_sale_price'])/100;
		setCartItems(temp_cart);
		setReCalcCart(!reCalcCart);
	}
	const removeFromCart = (cart) => {
		const index = cartItems.findIndex(item => item.iid === cart.iid);
		if (index !== -1) {
			const newArray = [...cartItems.slice(0, index), ...cartItems.slice(index + 1)];
			setCartItems(newArray);
			setReCalcCart(!reCalcCart);
			if( martCartMode === 'barcode' ){
				setMartCartMode('normal');
				setTimeout(() => {
					setMartCartMode('barcode');
				}, 1000);
			}
		}
	}
	const finalDocTotalCal = (otherCharge,roundoff,discount) => {
		if(otherCharge === '.' || roundoff === '.' || discount === '.'){
			return false;
		}
		if(otherCharge === '' && roundoff === '' && discount === ''){
			return false;
		}
		let total_amount = (Number(cartGst) + Number(cartSubTotal) + Number(otherCharge) - Number(discount)) + (Number(roundoff));
		setCartTotal(total_amount.toFixed(2));
	}
	const setCustomerDetails = (e) => {
		if(e === undefined) {
			setCustDetails({'id':0,'name':'','gstin':'','phone':''});
			return;
		}
		let temp = {};
		temp['id'] = e.id.startsWith('new') ? 0:e.id;
		temp['name'] = (e) ? e.party_name : '';
		temp['phone'] = (e.mobile) ? e.mobile : '';
		temp['gstin'] = (e.gstin) ? e.gstin : '';
		setCustDetails(temp);
	}
	const placeOrder = () => {
		let data = {};
		data['pid'] = localStorage.getItem('fo_profileid');
		data['martid'] = Common.secureLocalStorage.getItem('fo_martid');
		data['cid'] = cust_details.id;
		data['cust_name'] = cust_details.name;
		data['cust_gstin'] = cust_details.gstin;
		data['cust_phone'] = cust_details.phone;
		data['cust_legal_name'] = '';
		data['cust_trade_name'] = '';
		data['cust_addr'] = '';
		data['cust_pincode'] = '';
		data['cust_district'] = '';
		data['cust_state'] = '';
		data['cust_email'] = '';
		data['update_cust'] = 0;
		data['pos'] = Common.secureLocalStorage.getItem('fo_martpos');
		data['doc_date'] = new Date();
		data['total_taxable_amt'] = cartSubTotal;
		data['total_doc_discount'] = cartAddDiscount;
		data['round_off'] = cartRoundoff;
		data['other_charges'] = cartOtherCharge;
		data['total_amt'] = cartTotal;
		data['doc_type'] = 'INV';
		data['supply_type'] = (cust_details.gstin !== '' && cust_details.gstin.length === 15) ? 'B2B':'B2C';
		data['etin'] = '';
		data['rev_chrg'] = 'N';
		data['igst_intra'] = 'N';
		data['income_type'] = '1';
		data['income_subtype'] = '';
		data['payee'] = defaultPayee;
		data['soid'] = soid;
		data['qid'] = 0;
		data['doc_status'] = 'A';
		data['items'] = [];
		let errors = [];
		let docGstRate = [];
		let i = 0;
		cartItems.forEach(item => {
			let temp = {};
			if( item['service'] === '0' && Number(item['qty']) < Number(item['min_qty']) ){
				errors.push(item['name']+' : Minimum order qty. is '+Common.displayUnit(item['min_qty']));
				return false;
			}
			temp['id'] = item['id'];
			temp['name'] = item['name'];
			temp['item_qty'] = item['qty'];
			temp['unit'] = item['uqcid'];
			temp['hsn'] = item['hsn'];
			temp['price'] = Number(item['actual_sale_price'])+Number(item['discount']);
			temp['item_discount'] = item['qty']*item['discount'];
			temp['gst_rt'] = item['gst_rt'];
			temp['gst_rate_id'] = item['gst_rate_id'];
			temp['igst_amt'] = 0;
			temp['cgst_amt'] = temp['sgst_amt'] = (Number(item['qty']*(parseFloat(item['gst_rt'])/100)*item['actual_sale_price'])/2).toFixed(2);
			temp['item_tot_price'] = parseFloat(item['qty'])*parseFloat(item['actual_sale_price']) + parseFloat(temp['cgst_amt']) + parseFloat(temp['sgst_amt']);
			data['items'].push(temp);
			if(docGstRate.length > 0 ){
				var rate_exist = docGstRate.some(el => el.hasOwnProperty(item['gst_rt']));
				if(rate_exist){
					docGstRate.forEach( (val,index)=>{
						if(val.hasOwnProperty(item['gst_rt'])){
							let all_gst_amt = val[item['gst_rt']];
							let gst_amount = {};
							gst_amount['igst_amt'] = (Number(all_gst_amt['igst_amt']) + Number(temp['igst_amt'])).toFixed(2);
							gst_amount['sgst_amt'] = (Number(all_gst_amt['sgst_amt']) + Number(temp['sgst_amt'])).toFixed(2);
							gst_amount['cgst_amt'] = (Number(all_gst_amt['cgst_amt']) + Number(temp['cgst_amt'])).toFixed(2);
							docGstRate[index][item['gst_rt']] = gst_amount;
						}
					})
				}
				else{
					let gst_rate_arr = {};
					let gst_amount = {};
					gst_amount['igst_amt'] = Number(temp['igst_amt']).toFixed(2);
					gst_amount['cgst_amt'] = Number(temp['cgst_amt']).toFixed(2);
					gst_amount['sgst_amt'] = Number(temp['sgst_amt']).toFixed(2);
					gst_rate_arr[item['gst_rt']] = gst_amount;
					docGstRate[i] = gst_rate_arr;
				}
			}else{
				let gst_rate_arr = {};
				let gst_amount = {};
				gst_amount['igst_amt'] = Number(temp['igst_amt']).toFixed(2);
				gst_amount['cgst_amt'] = Number(temp['cgst_amt']).toFixed(2);
				gst_amount['sgst_amt'] = Number(temp['sgst_amt']).toFixed(2);
				gst_rate_arr[item['gst_rt']] = gst_amount;
				docGstRate[i] = gst_rate_arr;
			}
			i++;
		});
		if( docGstRate.length > 0 ){
			data['docGstRate'] = JSON.stringify(docGstRate);
		}
		data['items'] = JSON.stringify(data['items']);
		data['allocation_centre'] = '';
		data['poid'] = 0;
		data['qid'] = 0;
		data['service_type'] = martServiceType;
		data['address_id'] = address_id;

		if(martServiceType === ""){
			errors.push('Mart Service : Service option cannot be blank<br/>');
		}

		if(martServiceType === "home_delivery" && address_id === 0){
			errors.push('Mart Service : Address cannot be blank<br/>');
		}

		if( errors.length > 0 ){
			Common.showLoader(false);
			let err_list = '';
			errors.forEach((val, index) => {
				err_list += '<li>'+val+'</li>';
			})
			const err_html = () => (
				<ul><strong>List of Errors:</strong>{Common.parse(err_list)}</ul>
			);
			Common.toast.error(err_html);
			return false;
		}

		Common.axios({
			method: 'post',
			url: props.url+'sale_orders/edit',
			data: data
		}).then(function (response) {
			Common.showLoader(false);
			if(response.data.success){
				loadDataTable(1,perPage,filterText,dates,sortOrder);
				setRefWalletToken(!refWalletToken);
				return false;
			}
			Common.toast.error(response.data.message);
		}).catch(error => console.log(error));
	}
	const columns = [
        {
            name: 'Orders No.',
            selector: row => (row.inv_no === '' ? row.pre_inv_no : row.inv_no),
            sortable: true,
			sortField: 'inv_no'
        },
        {
			name: 'Orders Date',
            selector: row => row.inv_date,
            sortable: true,
			sortField: 'inv_date',
			cell: (row) => (
				Common.displayDate(row.inv_date)
			)
        },
        {
            name: 'Customer',
            selector: row => row.party_name,
            sortable: true,
			sortField: 'party_name'
        },
        {
            name: 'Total Amt (₹)',
            selector: row => row.total_amt,
            sortable: true,
			sortField: 'total_amt'
        },
		{
			name: 'Service',
            selector: row => (row.service_type === 'home_delivery' ? <Common.OverlayTrigger overlay={Common.popoverContent('','Home delivery')}><i className='bx bxs-home cursor-pointer' onClick={(e)=>getUserAddress(row)}></i></Common.OverlayTrigger> : (row.service_type === 'pick_up' ? <Common.OverlayTrigger overlay={Common.popoverContent('','Pick up')}><i className='bx bxs-truck'></i></Common.OverlayTrigger> : (row.service_type === 'reservation' ? <Common.OverlayTrigger overlay={Common.popoverContent('','Reservation')}><i className='bx bx-chair'></i></Common.OverlayTrigger> : ''))),
            sortable: true,
			sortField: 'service_type',
			center: true,
			conditionalCellStyles : [
				{
					when: row => row.service_type === 'home_delivery',
					style: {
						backgroundColor: 'inherit',
						color: 'green'
					},
				},
				{
					when: row => row.service_type === 'pick_up',
					style: {
						backgroundColor: 'inherit',
						color: 'orange'
					},
				},
				{
					when: row => row.service_type === 'reservation',
					style: {
						backgroundColor: 'inherit',
						color: 'Purple'
					},
				}
			]
		},
        {
            name: 'Created On',
            selector: row => row.created_on,
            sortable: true,
			sortField: 'created_on'
        },
        {
            name: 'Created By',
            selector: row => row.created_by,
            sortable: true,
			sortField: 'created_by'
        },
        {
            name: 'Updated On',
            selector: row => row.updated_on,
            sortable: true,
			sortField: 'updated_on'
        },
        {
            name: 'Updated By',
            selector: row => row.updated_by,
            sortable: true,
			sortField: 'updated_by'
        }
	];
	if(activeTab === 'Cancelled'){
		columns.push(
            {
                name: 'Cancel Reason',
                selector: row => row.cancel_reason,
                sortable: true,
                sortField: 'cancel_reason'
            }
        );
	}
	if(activeTab === 'Active' || activeTab === 'PreOrder'){
        columns.unshift(
            {
				name: 'Action',
				button: true,
				cell: (row) => (
					<div className="dropdown">
						<button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
						<i className="bx bx-dots-vertical-rounded"></i>
						</button>
						<div className="dropdown-menu" >
							{activeTab === 'Active' && <>
							{/* <button className="dropdown-item" onClick={(e) => handleEditOrders(row.id)}><i className='bx bxs-edit'></i> Edit Orders </button> */}
							<button className="dropdown-item" onClick={(e) => handleMakePayment(row.id)}><i className='bx bx-money'></i> Add Payment</button>
							<button className="dropdown-item" onClick={(e) => handleConvertInvoice(e,row.id)}><i className='bx bxs-file'></i> Convert to Invoice </button>
							</>}
							{activeTab === 'PreOrder' && <>
							<button className="dropdown-item" onClick={(e) => viewOrders(row.id)}><i className='bx bx-book-open'></i> View Order </button>
							</>}
							<button className="dropdown-item" onClick={(e) => handleCancelOrders(row)}><i className='bx bxs-x-square'></i> Cancel Order</button>
						</div>
					  </div>
				)
			}
        );
    }
	const invColumns = [
		{
			name: 'Action',
			button: true,
			cell: (row) => (
				<div className="dropdown">
					<button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
					<i className="bx bx-dots-vertical-rounded"></i>
					</button>
					<div className="dropdown-menu" >
						<Common.Link className="dropdown-item" to={"/mart-inv-print/"+ btoa(row.id)} target="_blank" rel="noreferrer"><i className='bx bx-printer' ></i> Generate Print</Common.Link>
					</div>
				  </div>
			)
		},
        {
            name: 'Invoice No.',
            selector: row => row.inv_no,
            sortable: true,
			sortField: 'inv_no'
        },
        {
            name: 'Invoice Date',
            selector: row => row.inv_date,
            sortable: true,
			sortField: 'inv_date',
			cell: (row) => (
				Common.displayDate(row.inv_date)
			)
        },
        {
            name: 'Customer',
            selector: row => row.party_name,
            sortable: true,
			sortField: 'party_name'
        },
        {
            name: 'Total Amt (₹)',
            selector: row => row.total_amt,
            sortable: true,
			sortField: 'total_amt'
        },
		{
			name: 'Service',
            selector: row => (row.service_type === 'home_delivery' ? <Common.OverlayTrigger overlay={Common.popoverContent('','Home delivery')}><i className='bx bxs-home cursor-pointer' onClick={(e)=>getUserAddress(row)}></i></Common.OverlayTrigger> : (row.service_type === 'pick_up' ? <Common.OverlayTrigger overlay={Common.popoverContent('','Pick up')}><i className='bx bxs-truck'></i></Common.OverlayTrigger> : (row.service_type === 'reservation' ? <Common.OverlayTrigger overlay={Common.popoverContent('','Reservation')}><i className='bx bx-chair'></i></Common.OverlayTrigger> : ''))),
            sortable: true,
			sortField: 'service_type',
			center: true,
			conditionalCellStyles : [
				{
					when: row => row.service_type === 'home_delivery',
					style: {
						backgroundColor: 'inherit',
						color: 'green'
					},
				},
				{
					when: row => row.service_type === 'pick_up',
					style: {
						backgroundColor: 'inherit',
						color: 'orange'
					},
				},
				{
					when: row => row.service_type === 'reservation',
					style: {
						backgroundColor: 'inherit',
						color: 'Purple'
					},
				}
			]
		},
        {
            name: 'Created On',
            selector: row => row.created_on,
            sortable: true,
			sortField: 'created_on'
        },
        {
            name: 'Created By',
            selector: row => row.created_by,
            sortable: true,
			sortField: 'created_by'
        },
        {
            name: 'Updated On',
            selector: row => row.updated_on,
            sortable: true,
			sortField: 'updated_on'
        },
        {
            name: 'Updated By',
            selector: row => row.updated_by,
            sortable: true,
			sortField: 'updated_by'
        }
	];
	const viewOrders = (id) => {
		Common.showLoader(true);
		Common.axios({
            method: 'post',
            url:props.url+'martorders/orderDetails',
			data: {sid:id}
        }).then(function (response) {
			Common.showLoader(false);
			if(response.data.success){
				setInvoiceDetails(response.data.data);
				setCartItems(response.data.itemData);
				setCustomerDetails(response.data.customerDetails);
				setShowCart(true);
				setCartSubTotal(response.data.data.total_taxable_amt);
				setCartGst(Number(response.data.data.igst_amt+response.data.data.cgst_amt+response.data.data.sgst_amt));
				setCartTotal(response.data.data.total_amt);
				setCartOtherCharge(response.data.data.other_charges);
				setCartRoundoff(response.data.data.round_off);
				setCartAddDiscount(response.data.data.total_doc_discount);
				setSoid(response.data.data.id);
				setMartServiceType(response.data.data.service_type);
				setAddressId(response.data.data.mart_user_address_id?response.data.data.mart_user_address_id:0);
				loadServiceData(response.data.data.created_by);
				return false;
			}
			Common.toast.error(response.data.message);
        }).catch(error => console.log(error));
	}
	const loadServiceData = (uid) => {
		Common.axios({
			method: 'post',
			url:props.url+'mart/getMartSettingsData',
			data: {martid:Common.AES.encrypt(Common.secureLocalStorage.getItem('fo_martid'), props.site_key).toString()}
		}).then(function (response) {
			if(response.data.success){
				setMartData(response.data.data);
				return false;
			}
			Common.toast.error(response.data.message);
		}).catch(error => console.log(error));

		Common.axios({
			method: 'get',
			url:props.url+'martorders/getMartUserAddress/'+uid,
		}).then(function (response) {
			if(response.data.success){
				setNewAddress(response.data.data);
			}else{
				Common.toast.error(response.data.message);
			}
		});
	}
	const confirmOrders = (id) => {
		setShowCart(false);
		confirmAlert({
            title: 'Confirm Order',
            message: 'Are you sure you want to Confirm this order?',
            buttons: [{
                label: 'Yes',
                onClick: () => {
					placeOrder();
				}
            },
            {
                label: 'No',
                onClick: () => {viewOrders(id)}
            }]
        });
	}
	const handleCancelOrders = (details)=>{
        setSaleOrdersId(details.id);
		setInvNo(details.inv_no);
        setSaleOrdersCancelRemarks('');
		setSaleOrdersCancelShow(true);
    }
	/*const handleGeneratePrint = (sid)=>{
		Common.showLoader(true);
		Common.axios({
			method: 'post',
			url:props.url+'martorders/invoiceDetails',
			data: {'sid':sid}
		}).then(function (response) {
			Common.showLoader(false);
			if(response.data.success){
				setInvoiceData(response.data.data);
				setInvItemData(response.data.itemData);
				setPaymentData(response.data.paymentData);
				setItemCount(response.data.itemCount);
				setTimeout(() => { handlePrint(); }, 1);
			}else{
				Common.toast.error(response.data.message);
			}
		}).catch(error => console.log(error));	
	}
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});*/
	const handleCancelSaleOrders = (e)=>{
        e.preventDefault();
        Common.showLoader(true);
        let formData = new FormData(e.target);
        let data = {};

        // convert the key/value pairs
		for (var [key, value] of formData.entries()) {
			data[key] = value;
		}
        data['saleOrdersId'] = saleOrdersId;
		data['pid']         = localStorage.getItem('fo_profileid');

		Common.axios({
            method: 'post',
            url:props.url+'sale_orders/cancelSaleOrders',
            data: data,
        }).then(function (response) {
			Common.showLoader(false);
			if(response.data.success){
				setSaleOrdersCancelShow(false);
                if(response.data.success === 1){
					setShowCart(false);
                    Common.toast.success(response.data.message);
					loadDataTable(1,perPage,filterText,dates,sortOrder);
					return false;
                }
                Common.toast.error(response.data.message);
                return false;
			}
            const err_html = () => (
				Common.parse(response.data.message)
			);
			Common.toast.error(err_html);
            Common.showLoader(false);
        }).catch(error => console.log(error));
    }
	const handleConvertInvoice =(e,val) => {
		e.preventDefault();
		confirmAlert({
            title: 'Convert Invoice',
            message: 'Are you sure you want to Convert to Invoice?',
            buttons: [{
                label: 'Yes',
                onClick: () => {
                    Common.showLoader(true);
					Common.axios({
						method: 'post',
						url:props.url+'martorders/getOrderData',
						data: {'sid':val}
					}).then(function (response) {
						if(response.data.success){
							let orderDetails = response.data.data;
							let itemData = response.data.itemData;
							let data = {};
							data['pid'] = localStorage.getItem('fo_profileid');
							data['martid'] = Common.secureLocalStorage.getItem('fo_martid');
							data['cid'] = orderDetails.cid;
							data['cust_name'] = orderDetails.party_name;
							data['cust_gstin'] = orderDetails.gstin;
							data['cust_phone'] = orderDetails.mobile;
							data['cust_legal_name'] = '';
							data['cust_trade_name'] = '';
							data['cust_addr'] = '';
							data['cust_pincode'] = '';
							data['cust_district'] = '';
							data['cust_state'] = '';
							data['cust_email'] = '';
							data['update_cust'] = 0;
							data['pos'] = Common.secureLocalStorage.getItem('fo_martpos');
							data['doc_date'] = new Date();
							data['total_taxable_amt'] = orderDetails.total_taxable_amt;
							data['total_doc_discount'] = orderDetails.total_doc_discount;
							data['round_off'] = orderDetails.round_off;
							data['other_charges'] = orderDetails.other_charges;
							data['total_amt'] = orderDetails.total_amt;
							data['total_deduction'] = '';
							data['total_payment'] = '';
							data['due'] = orderDetails.total_amt;
							data['doc_type'] = 'INV';
							data['supply_type'] = 'B2C';
							data['etin'] = '';
							data['rev_chrg'] = 'N';
							data['igst_intra'] = 'N';
							data['income_type'] = '1';
							data['income_subtype'] = '';
							data['items'] = [];
							let docGstRate = [];
							let i = 0;
							itemData.forEach(item => {
								console.log(item);
								let temp = {};
								temp['id'] = item.item_id;
								temp['name'] = item.name;
								temp['item_qty'] = item.qty;
								temp['unit'] = item.unit;
								temp['hsn'] = item.hsn;
								temp['price'] = item.price;
								temp['item_tot_price'] = item.amt;
								temp['item_discount'] = item.discount;
								temp['gst_rt'] = item.gst_rt;
								temp['gst_rate_id'] = item.gst_rate_id;
								temp['igst_amt'] = item.igst_amt;
								temp['cgst_amt'] = item.cgst_amt;
								temp['sgst_amt'] = item.sgst_amt;
								temp['item_service'] = item.service;
								temp['stock_movement'] = item.stock_movement;
								temp['item_service'] = item.service;
								data['items'].push(temp);
								if(docGstRate.length > 0 ){
									var rate_exist = docGstRate.some(el => el.hasOwnProperty(item['gst_rt']));
									if(rate_exist){
										docGstRate.forEach( (val,index)=>{
											if(val.hasOwnProperty(item['gst_rt'])){
												let all_gst_amt = val[item['gst_rt']];
												let gst_amount = {};
												gst_amount['igst_amt'] = (Number(all_gst_amt['igst_amt']) + Number(temp['igst_amt'])).toFixed(2);
												gst_amount['sgst_amt'] = (Number(all_gst_amt['sgst_amt']) + Number(temp['sgst_amt'])).toFixed(2);
												gst_amount['cgst_amt'] = (Number(all_gst_amt['cgst_amt']) + Number(temp['cgst_amt'])).toFixed(2);
												docGstRate[index][item['gst_rt']] = gst_amount;
											}
										})
									}
									else{
										let gst_rate_arr = {};
										let gst_amount = {};
										gst_amount['igst_amt'] = Number(temp['igst_amt']).toFixed(2);
										gst_amount['cgst_amt'] = Number(temp['cgst_amt']).toFixed(2);
										gst_amount['sgst_amt'] = Number(temp['sgst_amt']).toFixed(2);
										gst_rate_arr[item['gst_rt']] = gst_amount;
										docGstRate[i] = gst_rate_arr;
									}
								}else{
									let gst_rate_arr = {};
									let gst_amount = {};
									gst_amount['igst_amt'] = Number(temp['igst_amt']).toFixed(2);
									gst_amount['cgst_amt'] = Number(temp['cgst_amt']).toFixed(2);
									gst_amount['sgst_amt'] = Number(temp['sgst_amt']).toFixed(2);
									gst_rate_arr[item['gst_rt']] = gst_amount;
									docGstRate[i] = gst_rate_arr;
								}
								i++;
							});
							if( docGstRate.length > 0 ){
								data['docGstRate'] = JSON.stringify(docGstRate);
							}
							data['items'] = JSON.stringify(data['items']);
							data['allocation_centre'] = '';
							data['poid'] = 0;
							data['saveDraftInvoice'] = '1';
							data['submitType'] = '0';
							data['soid'] = orderDetails.id;
							Common.axios({
								method: 'post',
								url: props.url+'sales/add',
								data: data
							}).then(function (response) {
								Common.showLoader(false);
								if(response.data.success){
									loadDataTable(1,perPage,filterText,dates,sortOrder);
									return false;
								}
								Common.toast.error(response.data.message);
							}).catch(error => console.log(error));
							return false;
						}
						Common.toast.error(response.data.message);
					}).catch(error => console.log(error));
				}
            },
            {
                label: 'No',
                onClick: () => {console.log('no')}
            }]
        });
    }
	/*const handleEditOrders =(val) => {
        navigate('/add-sale-orders',
        {
            state: {
                soid: val
            }
        });
    }*/
	const handleMakePayment =(val) => {
        navigate('/mart-payment',
		{
			state: {
				sid: val
			}
		});
		return false;
    }
	const getMartServiceType = (actype) => {
		setMartServiceType(actype);
	}
	const getMartAddress = (event,id) => {
		setAddressId(event.target.checked ? id : 0);
	}
	const addNewaddress = () => {
		setFlatNumber('');setStreetName('');setLandmark('');setPincode('');setAddressAction('add');setMartAddressIndex(0);
		setNewAddressModal(true);
	}
	const editMartAdress = (data,inx) => {
		setFlatNumber(data.flat_number);
		setStreetName(data.street_name);
		setLandmark(data.landmark);
		setPincode(data.pincode);
		setEditAddressId(data.id);
		setAddressAction('edit');
		setMartAddressIndex(inx);
		setNewAddressModal(true);
	}
	const handleNewAddress = () => {
		if(flat_number === "" || street_name === "" || pincode === ""){
			Common.toast.error("Enter the mandatory Field");
			return false;
		}
		Common.axios({
            method: 'post',
            url:props.url+'martorders/addMartUserAddress',
            data: {flat_number:flat_number,street_name:street_name,landmark:landmark,pincode:pincode,address_action:address_action,address_id:edit_address_id},
        }).then(function (response) {
			if(response.data.success){
				let address = {};
				address['id'] = response.data.id;
				address['flat_number'] = flat_number;
				address['street_name'] = street_name;
				address['landmark'] = landmark;
				address['pincode'] = pincode;
				if(address_action === "edit"){
					newAddress[martAddressIndex] = address;
					setNewAddress(newAddress);
				}else{
					setNewAddress([...newAddress, address]);
				}
				Common.toast.success(response.data.message);
				setNewAddressModal(false);
			}else{
				Common.toast.error(response.data.message);
			}
		});
		setNewAddressModal(true);
	}
	const getUserAddress = (row) => {
		Common.showLoader(true);
		Common.axios({
            method: 'post',
            url:props.url+'martorders/getMartDeliveryAddress',
			data: {addressId:row.mart_user_address_id,cid:row.cid}
        }).then(function (response) {
			Common.showLoader(false);
			if(response.data.success){
				setAddressDetails(response.data.data);
				setDisplayAddressModel(true);
				return false;
			}
			Common.toast.error(response.data.message);
        }).catch(error => console.log(error));
	}
	const [showActiveDiv,setShowActiveDiv]       = React.useState(true);
    const [showCompletedDiv,setShowCompletedDiv] = React.useState(false);
    const [showCancelledDiv,setShowCancelledDiv] = React.useState(false);
	function TabGroup() {
		const handleTab = penColChecked => {
			setActCan(penColChecked);
			loadDataTable(1,perPage,filterText,dates,sortOrder,penColChecked);
			if(penColChecked === "Cancelled"){
				setShowActiveDiv(false);
				setShowCompletedDiv(false);
				setShowCancelledDiv(true);
			}
			else if(penColChecked === "Completed"){
				setShowActiveDiv(false);
				setShowCompletedDiv(true);
				setShowCancelledDiv(false);
			}
			else{
				setShowActiveDiv(true);
				setShowCompletedDiv(false);
				setShowCancelledDiv(false);
			}
		};
		return (
			<>
			<ButtonGroup className="tabWithInfo">
				{martTabTypes.map(type => (
				<Tab
					key={type}
					active={activeTab === type}
					onClick={() => handleTab(type)}
				>
					{(totalDataCnt[type] !== '') ? type + ' ('+totalDataCnt[type]+')' : type}
				</Tab>
				))}
			</ButtonGroup>
			</>
		);
	}
    return (
	<>
		{/* <Offcanvas placement={'end'} show={showCart} onHide={()=>{setShowCart(false);setCartOtherCharge('');setCartRoundoff('');setCartAddDiscount('')}}>
			<Offcanvas.Header closeButton>
				<Offcanvas.Title>Order Details : {invoiceDetails.inv_no}</Offcanvas.Title>
			</Offcanvas.Header>
			<Offcanvas.Body>
				<div className="table-responsive">
					<table id="cartTable" className="table table-sm table-hover table-borderless align-middle">
						<thead>
							<tr><th style={{'width':'10%'}}></th><th style={{'width':'70%'}}>Item</th><th style={{'width':'20%'}} className="text-end">Qty</th></tr>
						</thead>
						<tbody>
						{itemDetails.map((item,index) => {
							return (
								<tr key={"iCart"+index}>
									<td>
									{item.media_name !=='' && item.media_name !== null ? <>									
										{ (item.media_type === 'image/png' || item.media_type === 'image/jpeg') ?
											<img className="border rounded" src={item.media_name} width="50" height="auto" alt={"Items Cart"+index}/>
										:
											<video className="w-100" controls>
												<source src={item.media_name} type={item.media_type}/>
												Your browser does not support the video tag.
											</video>
										}
										</>
									: <img className="border rounded" src={window.location.origin+"/assets/img/no-media.png"} height="auto" width="50" alt={"Items Cart"+index}/>
									}
									</td>
									<td>{item.name}<br/><small className="text-success">{Common.displayPrice(item.amt)}</small></td>
									<td className="text-end" width="30%">
										{item.qty}
									</td>
								</tr>
							)
						})}
						</tbody>
					</table>
				</div>
				<hr/>
				<div className="list-group small_font">
					<div className="list-group-item list-group-item-action text-nowrap">Subtotal: <span className="float-end">{Common.displayPrice(invoiceDetails.total_taxable_amt)}</span></div>
					<div className="list-group-item list-group-item-action text-nowrap">GST: <span className="float-end">{Common.displayPrice(parseFloat(invoiceDetails.igst_amt)+parseFloat(invoiceDetails.cgst_amt)+parseFloat(invoiceDetails.sgst_amt))}</span></div>
					<div className="list-group-item list-group-item-action text-nowrap">
						<div className="row">
							<div className="col-8">
								<span>Additional discount:</span>
							</div>
							<div className="col-4">
								<input type="text" autoComplete="off" className="form-control form-control-sm text-end" id="frmSaleDocDiscount" value={cartAddDiscount} placeholder="0.00" onChange={(e) => { setCartAddDiscount(Common.acceptOnlyDecimal(e.target.value)); }} />
							</div>
						</div>
					</div>
					<div className="list-group-item list-group-item-action text-nowrap">
						<div className="row">
							<div className="col-8">
								<span>Other charges:</span>
							</div>
							<div className="col-4">
								<input type="text" autoComplete="off" className="form-control form-control-sm text-end" id="frmSaleDocOtherCharge" value={cartOtherCharge} placeholder="0.00" onChange={(e) => { setCartOtherCharge(Common.acceptOnlyDecimal(e.target.value));}} />
							</div>
						</div>
					</div>
					<div className="list-group-item list-group-item-action text-nowrap">
						<div className="row">
							<div className="col-8">
								<span>Round off:</span>
							</div>
							<div className="col-4">
								<input type="number" autoComplete="off" className="form-control form-control-sm text-end" id="frmSaleDocRoundoff" value={cartRoundoff} placeholder="0.00" onChange={(e) => { handleDocRoundoff(e.target.value); }} />
							</div>
						</div> 
					</div>
					<div className="list-group-item list-group-item-action text-nowrap active">Total: <span className="float-end">{Common.displayPrice(totalInvoiceValue)}</span></div>
				</div>
				<div className="pt-4 row text-center">
					<div className="col-6 d-grid">
						<Button onClick={()=>handleCancelOrders(invoiceDetails)} variant="danger" type="button" size="sm"><i className='bx bx-x'></i> Cancel</Button>
					</div>
					<div className="col-6 d-grid">
						<Button onClick={()=>confirmOrders(invoiceDetails.id)} variant="success" type="button" size="sm"><i className='bx bx-check'></i> Confirm</Button>
					</div>
				</div>
			</Offcanvas.Body>
		</Offcanvas> */}
		<Offcanvas id="vendor_cart" placement={'end'} show={showCart} onHide={()=>{setShowCart(false);setMartCartMode('normal')}}>
			<Offcanvas.Header closeButton>
				<Offcanvas.Title className="row row-cols-auto w-100 align-items-center">
					<div className="col-4 col-lg-3">My Cart</div>
					<div className="col-5 col-lg-7 p-0">
						<AsyncTypeahead selected={[]} filterBy={filterBy} id="item-cart" isLoading={isItemLoading} labelKey="name" minLength={3} onSearch={itemsSearch} options={itemsCart} placeholder="Search (min 3 chars)" onChange={(e) => addToCart(e[0])}
							renderMenuItemChildren={(option: Item,props) => (
								<div className="smaller_font">
									<Highlighter search={props.text}>
										{option.name}
									</Highlighter>
									<div>
										<small className="text-success text-nowrap">Price: {Common.displayPrice(option.sale_price)}</small>
										{option.service === '0' && option.stock_movement === '1' && <>
										, <small>Available: {option.current_stock === '0.00' ? 'Out of Stock' : Number(option.current_stock)}</small>
										</>}
									</div>
								</div>
							)}
						/>
					</div>
					<div className="col-2 col-lg-2">
						<Common.OverlayTrigger placement={'bottom'} overlay={Common.tooltipContent('Scan Barcodes')}>
							<Button onClick={()=>setMartCartMode( (martCartMode==='barcode') ? 'normal':'barcode')} variant="outer-primary" className={"p-0 "+(martCartMode==='barcode' ? "text-success" : "") }><i className={"bx bx-lg bx-barcode-reader"+(martCartMode==='barcode' ? " bx-burst":"")}></i></Button>
						</Common.OverlayTrigger>
					</div>
				</Offcanvas.Title>
			</Offcanvas.Header>
			<Offcanvas.Body>
				<div className={"row pb-2 text-center "+ (martCartMode === 'normal' ? "d-none":"")}>
					<div className={"mx-auto "+(martCartMode === 'normal' ? "d-none":"")} style={{'width':'320px','height':'auto'}}>
						<h5>Scan Barcodes</h5>
						<div id="barCartReader" className="d-block"></div>
					</div>
				</div>
				{cartItems.length === 0 ? <><div className="text-center"><small>You have not added anything to cart.</small></div></>
				:
				<div className="table-responsive">
					<table id="cartTable" className="small_font table table-sm table-hover table-borderless align-middle">
						<thead>
							<tr>
								<th style={{'width':'10%'}}></th>
								<th style={{'width':'40%'}}>Item</th>
								<th style={{'width':'15%'}} className="text-end">Qty</th>
								<th style={{'width':'30%'}} className="text-end">Price/Unit</th>
								<th style={{'width':'5%'}} className="text-end"></th>
							</tr>
						</thead>
						<tbody>
						{cartItems.map((item,index) => {
							return (
								<tr key={"iCart"+index}>
									<td>
									{item.media_name ? <>									
										{ (item.media_type === 'image/png' || item.media_type === 'image/jpeg') ?
											<img className="border rounded" src={item.media_name} width="50" height="auto" alt={"Items Cart"+index}/>
										:
											<video className="w-100" controls>
												<source src={item.media_name} type={item.media_type}/>
												Your browser does not support the video tag.
											</video>
										}
										</>
										: <img className="border rounded" src={window.location.origin+"/assets/img/no-media.png"} height="auto" width="50" alt={"Items Cart"+index}/>
									}
									</td>
									<td>
										{item.name}<br/>
										<span className="smaller_font text-success text-nowrap">{Common.displayPrice(item.sale_price-item.discount)}</span>
										{ item.discount > 0 && <><br/><span className="smaller_font text-decoration-line-through text-muted">{Common.displayPrice(item.sale_price)}</span></>}
									</td>
									<td valign="top">
										<Common.OverlayTrigger placement={'top'} overlay={Common.tooltipContent('Change Quantity')}>
										<input onChange={(e)=>updateCart(index,Common.acceptOnlyDecimal(e.target.value))} type="text" step=".001" min="0" className="text-right form-control form-control-sm" name="qty" value={item.qty} autoComplete="off"/>
										</Common.OverlayTrigger>
									</td>
									<td valign="top" align="right">
										<input type="text" name="price" className="text-right form-control form-control-sm" value={item.actual_sale_price} onChange={(e)=>updateItemPrice(index,e.target.value)}/>
										<span className="smaller_font text-success text-nowrap">Tot: {Common.displayPrice(item.qty*(item.actual_sale_price))}</span>
									</td>
									<td valign="top">
										<Common.OverlayTrigger placement={'top'} overlay={Common.tooltipContent('Remove from Cart')}>
											<Button onClick={()=>removeFromCart(item)} size="sm" variant="link"><i className='bx bx-trash'></i></Button>
										</Common.OverlayTrigger>
									</td>
								</tr>
							)
						})}
						</tbody>
					</table>
				</div>
				}
				<hr/>
				<div className="list-group small_font">
					<div className="list-group-item list-group-item-action text-nowrap">Subtotal: <span className="float-end">{Common.displayPrice(cartSubTotal)}</span></div>
					<div className="list-group-item list-group-item-action text-nowrap">GST: <span className="float-end">{Common.displayPrice(cartGst)}</span></div>
					<div className="list-group-item list-group-item-action text-nowrap">
						<div className="row">
							<div className="col-8">
								<span>Additional discount:</span>
							</div>
							<div className="col-4">
								<input type="text" autoComplete="off" className="form-control form-control-sm text-end" id="frmSaleDocDiscount" value={cartAddDiscount} placeholder="0.00" onChange={(e) => { setCartAddDiscount(Common.acceptOnlyDecimal(e.target.value));finalDocTotalCal(cartOtherCharge,cartRoundoff,Common.acceptOnlyDecimal(e.target.value)); }} />
							</div>
						</div>
					</div>
					<div className="list-group-item list-group-item-action text-nowrap">
						<div className="row">
							<div className="col-8">
								<span>Other charges:</span>
							</div>
							<div className="col-4">
								<input type="text" autoComplete="off" className="form-control form-control-sm text-end" id="frmSaleDocOtherCharge" value={cartOtherCharge} placeholder="0.00" onChange={(e) => { setCartOtherCharge(Common.acceptOnlyDecimal(e.target.value));finalDocTotalCal(Common.acceptOnlyDecimal(e.target.value),cartRoundoff,cartAddDiscount); }} />
							</div>
						</div>
					</div>
					<div className="list-group-item list-group-item-action text-nowrap">
						<div className="row">
							<div className="col-8">
								<span>Round off:</span>
							</div>
							<div className="col-4">
								<input type="number" autoComplete="off" className="form-control form-control-sm text-end" id="frmSaleDocRoundoff" value={cartRoundoff} placeholder="0.00" onChange={(e) => { handleDocRoundoff(e.target.value); }} />
							</div>
						</div> 
					</div>
					<div className="list-group-item list-group-item-action text-nowrap active">Total: <span className="float-end">{Common.displayPrice(cartTotal)}</span></div>
				</div>
				{(martData.home_delivery_service === "Y" || martData.pick_up_service === "Y" || martData.reservation_service === "Y") && <>
					<hr/>
					<div className="small_font">
						<label className="form-label">Select service option <span className="text-danger">*</span></label><br></br>
						{martData.home_delivery_service === "Y" && <>
							<div className="form-check form-check-inline">
								<input id="home_delivery_service" name="home_delivery_service" className="form-check-input" type="radio" value="home_delivery"  required checked={martServiceType === 'home_delivery'} onChange={(e) => getMartServiceType(e.target.value)} />
								<label className="form-check-label" htmlFor="home_delivery_service"> Home Delivery/Service </label>
							</div>
						</>}
						{martData.pick_up_service === "Y" && <>
							<div className="form-check form-check-inline">
								<input id="pick_up_service" name="pick_up_service" className="form-check-input" type="radio" value="pick_up" required  checked={martServiceType === 'pick_up'} onChange={(e) => getMartServiceType(e.target.value)} />
								<label className="form-check-label" htmlFor="pick_up_service"> Pick up </label>
							</div>
						</>}
						{martData.reservation_service === "Y" && <>
							<div className="form-check form-check-inline">
								<input id="reservation_service" name="reservation_service" className="form-check-input" type="radio" value="reservation" required  checked={martServiceType === 'reservation'} onChange={(e) => getMartServiceType(e.target.value)} />
								<label className="form-check-label" htmlFor="reservation_service"> Reservation </label>
							</div>
						</>}
					</div>
					{martServiceType === "home_delivery" && <>
						<div className="small_font">
						{newAddress.length > 0 && <>
						<div className="row m-0 px-4">
							{newAddress.map((val,index) => (
								<div className="col-12 mb-2" key={'address_'+index}>
									<div className="address_div px-2 py-2">
										<div className="row">
											<div className="col-10">
												<div className="form-check form-check-inline">
													<input id={"new_address_"+index} name={"new_address_"+index} className="form-check-input" type="radio" value={val.id} checked={address_id === val.id?true:false} onChange={(e) => getMartAddress(e,val.id)} />
													<label className="form-check-label" htmlFor={"new_address_"+index}> {val.flat_number}<br></br>{val.street_name}<br></br>{val.landmark}{val.landmark?<br></br>:''}{val.pincode}</label>
												</div>
											</div>
											<div className="col-2">
												<span onClick={(e) => editMartAdress(val,index)}><Common.OverlayTrigger overlay={Common.popoverContent('','Edit')}><i className='bx bx-edit'></i></Common.OverlayTrigger></span>
											</div>
										</div>
									</div>
								</div>
							))}
						</div>
						</>}
							<button className="btn btn-link text-decoration-underline" onClick={() => addNewaddress()}><i className='bx bx-plus' ></i>Add new address</button>
						</div>
					</>}
				</>}
				{cartTotal > 0 &&
				<div className="d-grid gap-2 pt-4">
					<div className="row text-center">
						<div className="col-6 d-grid">
							<Button onClick={()=>handleCancelOrders(invoiceDetails)} variant="danger" type="button" size="md"><i className='bx bx-x'></i> Cancel</Button>
						</div>
						<div className="col-6 d-grid">
							<Button onClick={()=>confirmOrders(invoiceDetails.id)} variant="success" type="button" size="md"><i className='bx bx-check'></i> Confirm</Button>
						</div>
					</div>
				</div>
				}
			</Offcanvas.Body>
		</Offcanvas>
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Layout label="mart-orders" {...props}/>
                <div className="layout-page">
                    <NavBar refresh={refWalletToken} {...props}/>
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                            <h4 className="fw-bold py-3 mb-3">Orders
								<span className="dropdown">
									<button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
									<i className='text-danger bx bxl-youtube bx-md'></i>
									</button>
									<div className="dropdown-menu" >
										<a href="https://youtu.be/PVraJMXbwtY?si=vjeWfIqTRgGzYCc1" className="dropdown-item" target="_blank" rel="noreferrer"><span>Manage customer pre-orders</span></a>
										<a href="https://youtu.be/42-7mAk7srg" className="dropdown-item" target="_blank" rel="noreferrer"><span>Create an order</span></a>
									</div>
								</span>
							</h4>
							<div className="row">
								<div className="col-md-4 col-12 mb-1 text-center text-md-start">
									<Common.OverlayTrigger placement={'top'} overlay={Common.tooltipContent('Refresh')}>
										<Button variant="primary" className="btn-sm me-1" onClick={()=>{setDtLoad(!dt_load);setDTPending(true)}}><i className='bx bx-refresh'></i></Button>
									</Common.OverlayTrigger>
									<a href="/mart-add-order" className="btn btn-sm btn-primary"><i className='bx bx-plus'></i><span className="hidden-phone">Order</span></a>
								</div>
								<div className="col-md-4 col-12 text-center mb-1" >
									<DateRangePicker
										initialSettings={DTE.DatePickerPredefined(moment,start,end)}
										onCallback={setDatePicker}>
										<div className="input-group input-group-sm">
											<span title="Date Range" className="input-group-text"><i className='bx bxs-calendar'></i></span>
											<div id="reportrange" className="form-control form-control-sm" style={{ cursor: 'pointer',padding:'5px 0px',fontSize:'small'}}>
												<i className="fa fa-calendar"></i>&nbsp;<span>{label}</span> <i className="fa fa-caret-down"></i>
											</div>
										</div>
									</DateRangePicker>
									<small>Date range based on Order Date</small>
								</div>
								<div className="col-md-4 col-12 text-center text-md-end mb-1">
									<Common.OverlayTrigger overlay={Common.popoverContent('Search By:','Customer Name, Document Number')}><i className='bx bxs-info-circle bx-tada me-1'></i></Common.OverlayTrigger>
									<DTE.FilterComponent placeholder="Search" onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
								</div>
							</div>
                            <div className="row">
								<div className="pb-3">
									<div className="col-md-12">
										<TabGroup/>
									</div>
								</div>
								{showActiveDiv && (<>
									<div className="col-md-12">
										<DTE.DataTable className="dataTables_wrapper rdt_AdjDrp" columns={columns} data={orders} progressPending={DTpending} pagination paginationServer paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} onSort={handleSort} sortServer paginationResetDefaultPage={dt_load}/>
									</div>
								</>)}
								{showCompletedDiv && (<>
									<div className="col-md-12">
										<DTE.DataTable className="dataTables_wrapper rdt_AdjDrp" columns={invColumns} data={orders} progressPending={DTpending} pagination paginationServer paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} onSort={handleSort} sortServer paginationResetDefaultPage={dt_load}/>
									</div>
								</>)}
								{showCancelledDiv && (<>
									<div className="col-md-12">
										<DTE.DataTable className="dataTables_wrapper rdt_AdjDrp" columns={columns} data={orders} progressPending={DTpending} pagination paginationServer paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} onSort={handleSort} sortServer paginationResetDefaultPage={dt_load}/>
									</div>
								</>)}
                            </div>
                        </div>
                    </div>
                    <Common.FYNTicket url={props.url}/>
                </div>
            </div>
        </div>
		{/*<div id="printMartDiv" ref={componentRef} style={{'width':'100%','padding':'5px','color':'black'}}>
			<div style={{'textAlign':'center'}}>
				{invoiceData.logo && (<>
					<div style={{'display':'flex','justify-content':'center'}}>
						<img
							src = {props.url+"public/assets/img/profile_logo/"+invoiceData.logo}
							alt="user-avatar"
							className="d-block rounded"
							height="40"
							width="40"
							id="uploadedAvatar"
						/>
					</div>
				</>)}
				<p style={{'fontSize':'15px','fontWeight':'bold','marginTop':'5px','marginBottom':'5px'}} ><span>{invoiceData.name}</span></p>
				<div className="top_header" dangerouslySetInnerHTML={{ __html: (invoiceData.contact_billing_details) }} />
				<div style={{'fontSize':'12px'}}>GSTIN:{invoiceData.gstin}</div>
				<p style={{'fontSize':'15px','fontWeight':'bold','marginTop':'5px','marginBottom':'5px'}} ><span>Tax Invoice</span></p>
			</div>
			<div>
				<div style={{'display':'inline','float':'left'}}><small className="font-9">Inv No: {invoiceData.inv_no}</small></div>
				<div style={{'display':'inline','float':'right'}}><small className="font-9">Date: {Common.displayDate(invoiceData.inv_date)}</small></div>
			</div>
			<div>
				<table style={{'width':'100%'}}>
					<thead style={{'borderTop':'1px solid','borderBottom':'1px solid'}}>
						<tr>
							<th className="font-12">S/N</th>
							<th className="font-12">Item Details</th>
							<th className="font-12" style={{'textAlign':'right'}}>Qty</th>
							<th className="font-12" style={{'textAlign':'right'}}>Amount</th>
						</tr>
					</thead>
					<tbody>
						{invItemData.map((val,index) => (
							<tr key={'item'+index}>
								<td className="font-12">{index+1}</td>
								<td className="font-12">{val.name}<br></br>
									<span style={{'fontSize':'9px'}}>{' ₹'+val.gross_amt}</span><br></br>
									<span style={{'fontSize':'9px'}}>{(invoiceData.customer_pos === invoiceData.seller_pos)?'SGST '+Number(val.gst_rt/2)+'% | CGST '+Number(val.gst_rt/2)+'%':'IGST '+val.gst_rt+'%'}</span>
								</td>
								<td className="font-12" align="right">{(Number(val.qty) % 1 !== 0)?val.qty:Math.trunc(val.qty)}</td>
								<td className="font-12" align="right">{val.amt}</td>
							</tr>
						))}
						<tr style={{'borderTop':'1px solid','borderBottom':'1px solid'}}>
							<td colSpan="2" align="center"><b className="font-12">Total</b></td>
							<td align="right"><b className="font-12">{(Number(itemCount) % 1 !== 0)?itemCount:Math.trunc(itemCount)}</b></td>
							<td align="right"><b className="font-12">{invoiceData.total_amt}</b></td>
						</tr>
					</tbody>
				</table>
			</div>
			<div style={{'paddingTop':'10px'}}>
				<div style={{'display':'inline','float':'left'}}><p style={{'fontSize':'15px','fontWeight':'bold','marginTop':'5px','marginBottom':'5px'}} ><span>Total Amount:</span></p></div>
				<div style={{'display':'inline','float':'right','borderTop':'1px solid','borderBottom':'1px solid'}}><p style={{'fontSize':'15px','fontWeight':'bold','marginTop':'5px','marginBottom':'5px'}} ><span>₹ {invoiceData.total_amt}</span></p></div>
			</div>
			{paymentData.length>0 && (<>
				<div style={{'paddingTop':'10px'}}>
					<div style={{'display':'inline','float':'right','width':'100%','textAlign':'center'}}>
						<p className="font-12" style={{'borderBottom':'1px solid','marginBottom':'0px'}}>Payment Details</p>
						{paymentData.map((val) => (
							<p className="font-12" style={{'margin':'0px'}}>{val.payment_mode}: {val.paid_amt}</p>
						))}
					</div>
				</div>
			</>)}
			<br></br>
			<br></br>
			{Number(invoiceData.discount_amt) > 0 && (<>
				<div style={{'textAlign':'center'}}>
					<p style={{'fontSize':'15px','fontWeight':'bold','marginTopmargin':'5px','marginBottom':'5px'}} >Your Savings : Rs. {invoiceData.discount_amt}</p>
				</div>
			</>)}
			<div className="font-12" style={{'textAlign':'center','marginTop':'4px'}}>
			<br></br>
				Thank You. Visit Again 
				<p><b>Powered by FynOne</b></p>
			</div>
		</div>*/}
		<Modal size='md' show={saleOrdersCancelShow} onHide={()=>setSaleOrdersCancelShow(false)} aria-labelledby="Sale-Orders-Cancel-modal" backdrop="static" keyboard={false} centered>
			<Modal.Header closeButton>
				<Modal.Title id="Sale-Orders-Cancel-modal">Cancel Sale Order ({invNo})</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<form id="addEwbCancelFrm" onSubmit={handleCancelSaleOrders} autoComplete="off">
					<div className="row">
						<div className="col-sm-12">
                            <label htmlFor="cancel-reason" className="form-label">Cancel Reason <span className="text-danger">*</span></label>
                            <Select id="cancel-reason" placeholder="Select Cancel Reason" name='cancel_reason' options={Common.ewayBillCancelReason} required/>
						</div>
                        <div className="col-sm-12">
                            <label htmlFor="cancel-remarks" className="form-label">Cancel Remarks <span className="text-danger">*</span></label>
                            <div className="input-group input-group-merge">
                                <textarea rows="2" id="cancel-remarks" name="cancel_remarks" className="form-control" placeholder="Cancel Remarks" aria-label="CancelRemarks" aria-describedby="Remarks" value={saleOrdersCancelRemarks} onChange={(e) => setSaleOrdersCancelRemarks(e.target.value)} required></textarea>
                            </div>
						</div>
					</div>
					<div className="row ">
						<div className="col-sm-12 text-center mt-3">
							<button type='submit' className="btn btn-sm btn-danger"><i className='bx bx-message-square-x' ></i>&nbsp;Cancel</button>
						</div>
					</div>
				</form>
			</Modal.Body>
		</Modal>
		<Modal size='md' show={newAddressModal} onHide={()=>setNewAddressModal(false)} aria-labelledby="new-address-modal" backdrop="static" keyboard={false} centered>
			<Modal.Header closeButton>
				<div className="col-6 modal-mobile-view">
					<Modal.Title id="advance-modal">{address_action === "add"?"Add":"Edit"} address</Modal.Title>
				</div>
			</Modal.Header>
			<Modal.Body>
			<form id="frmNewAddress" autoComplete="off">
				<div className="row">
					<div className="mb-3 col-4">
						<div className="input-group input-group-merge">
							<label htmlFor="flat_number">Flat Number <span className="text-danger">*</span></label>
						</div>
					</div>
					<div className="mb-3 col-8">
						<div className="input-group input-group-merge">
							<input type="text" className="form-control" id="flat_number" name="flat_number" value={flat_number}
							onChange={(e) => setFlatNumber(e.target.value)} placeholder="Flat Number" maxLength="30" />
						</div>
					</div>
				</div>
				<div className="row">
					<div className="mb-3 col-4">
						<div className="input-group input-group-merge">
							<label htmlFor="street_name">Street Name <span className="text-danger">*</span></label>
						</div>
					</div>
					<div className="mb-3 col-8">
						<div className="input-group input-group-merge">
							<input type="text" className="form-control" id="street_name" name="street_name" value={street_name}
							onChange={(e) => setStreetName(e.target.value)} placeholder="Street Name" maxLength="30" />
						</div>
					</div>
				</div>
				<div className="row">
					<div className="mb-3 col-4">
						<div className="input-group input-group-merge">
							<label htmlFor="landmark">Landmark</label>
						</div>
					</div>
					<div className="mb-3 col-8">
						<div className="input-group input-group-merge">
							<input type="text" className="form-control" id="landmark" name="landmark" value={landmark}
							onChange={(e) => setLandmark(e.target.value)} placeholder="Landmark(Optional)" maxLength="30" />
						</div>
					</div>
				</div>
				<div className="row">
					<div className="mb-3 col-4">
						<div className="input-group input-group-merge">
							<label htmlFor="pincode">Pincode <span className="text-danger">*</span></label>
						</div>
					</div>
					<div className="mb-3 col-8">
						<div className="input-group input-group-merge">
							<input type="text" className="form-control" id="pincode" name="pincode" value={pincode}
							onChange={(e) => setPincode(Common.acceptOnlyNumbers(e.target.value))} placeholder="Pincode" minLength="6" maxLength="6" />
						</div>
					</div>
				</div>
				<div className="text-center mt-3">
					<Button variant="primary" size="sm" className="ms-1" onClick={()=>handleNewAddress()}><span className="tf-icons bx bx-save"></span>&nbsp;{address_action === "add"?"Add":"Update"}</Button>
				</div>
			</form>
			</Modal.Body>
		</Modal>
		<Modal show={displayAddressModel} size='sm' onHide={()=>setDisplayAddressModel(false)} aria-labelledby="UPI-modal" backdrop="static" keyboard={false} centered>
			<Modal.Header closeButton>
			<Modal.Title>Delivery Address</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="row">
					<div className="col-sm-12">
						<p>{addressDetails}</p>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="success" onClick={()=>setDisplayAddressModel(false)}>
					OK
				</Button>
			</Modal.Footer>
		</Modal>
	</>
    );
}