import React,{useState,useEffect} from 'react';
import Layout from './Layout';
import MartLayout from './MartLayout';
import NavBar from './NavBar';
import MartNavBar from './MartNavBar';
import Select from "react-select";
import * as Common from '../common/ImportUserEssentials';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import 'bootstrap-daterangepicker/daterangepicker.css';
import * as DPE from '../common/ImportDatePickerEssentials';
import * as DTE from '../common/ImportDataTableEssentials';
import Modal from 'react-bootstrap/Modal';

/*import { MultiSelect } from 'primereact/multiselect';
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";
import '../styles/multiselect.css';*/
import '../styles/dashboard.css';

ChartJS.register(ArcElement, Tooltip, Legend);

export default function Profile(props){	
	// const checkboxes = ['primary'];
	const [checkAll,setCheckAll] = React.useState(false);
	const basedon_opt = [
		{ value: "customer", label: "Customer" },
		{ value: "allocation_center", label: "Allocation Center" },
		{ value: "invoice_creator", label: "Invoice Creator" },
		{ value: "doc_type", label: "Doc Type" },
		{ value: "income_type", label: "Income Type - Sub Type" }
	];
	const doc_statuses = [
		{ value: "A", label: "Active" },
		{ value: "D", label: "Draft" },
		{ value: "C", label: "Cancelled" }
	];
	/*const doc_types = [
		{ value: "INV", label: "Invoice" },
		{ value: "CRN", label: "Credit Note" },
		{ value: "DBN", label: "Debit Note" }
	];*/

	// collections options
	const status_opt = [
		{ value: "Dues", label: "Dues" },
		{ value: "Collected", label: "Collected" },
	];
	const basedOnCollection_opt = [
		{ value: "Debtor Days", label: "Debtor Days" },
		{ value: "Customer", label: "Customer" },
		{ value: "Creator", label: "Creator" },
	];
	const basedOnCollection_collected_select_opt = [
		{ value: "Customer", label: "Customer" },
		{ value: "Creator", label: "Creator" },
		{ value: "PaymentMode", label: "Payment Mode" },
		{ value: "BankAccount", label: "Bank Account" }
	];
	/*const payment_mode_opt = [
		{ value: "Cash", label: "Cash" },
		{ value: "UPI/QR", label: "UPI/QR" },
		{ value: "Mobile Wallets", label: "Mobile Wallets" },
		{ value: "Cheque/DD", label: "Cheque/DD" },
		{ value: "neft/rtgs/imps", label: "neft/rtgs/imps" },
		{ value: "Netbanking", label: "Netbanking" },
		{ value: "EMI", label: "EMI" },
		{ value: "BNPL", label: "BNPL" },
		{ value: "Credit/Debit Card", label: "Credit/Debit Card" },
	];*/
	// other options
	const status_opt_oth = [
		{ value: "Dues", label: "Dues" },
		{ value: "Collected", label: "Collected" },
	];
	const basedon_opt_oth = [
		{ value: "Creator", label: "Creator" },
		{ value: "oth_inc_type", label: "Other Income type - Sub type" },
	];
	const basedon_opt_oth_collected = [
		{ value: "oth_inc_type_collected", label: "Other Income type - Sub type" },
	];
	const [statusOther, setStatusOther] = useState(status_opt_oth[0].value)
	const [basedonOther, setBasedonOther] = useState(basedon_opt_oth[0].value)
	const [isDueSelectedOth, setIsDueSelectedOth] = useState(true);
	const [defaultValOth,setDefaultValOth] = useState(basedon_opt_oth[0]);

	const [basedon, setBasedon] = useState(basedon_opt[0].value);
	const [docstatus,setDocStatus] = useState(doc_statuses[0].value);
	const doctype = ['INV','CRN','DBN'];
	//const [doctype,setDoctype] = useState(['INV','CRN','DBN']);
	// datatables
	const [saleData,setSaleData] = useState([]);
	const [dates, setDates] = React.useState({
		start: moment().startOf('month'),
		end: moment().endOf('month'),
    });
	const [secondTDates, setsecondTDates] = React.useState({
		secondStart: moment().startOf('month'),
		secondEnd: moment().endOf('month'),
    });
	const [thirdDates, setThirdDates] = React.useState({
		thirdStart: moment().startOf('month'),
		thirdEnd: moment().endOf('month'),
    });
	// const [displayDocType, setDisplayDocType] = useState(false);
	const [pieData,setPieData] = useState([]);
	const [pieCollectionData,setPieCollectionData] = useState([]);
    const { start, end } = dates;
    const { secondStart, secondEnd } = secondTDates;
    const { thirdStart, thirdEnd } = thirdDates;
	
	const label = start.format('DD-MM-YYYY') + ' ~ ' + end.format('DD-MM-YYYY');
	const secondlabel = secondStart.format('DD-MM-YYYY') + ' ~ ' + secondEnd.format('DD-MM-YYYY');
	const thirdlabel = thirdStart.format('DD-MM-YYYY') + ' ~ ' + thirdEnd.format('DD-MM-YYYY');
	
	const setDatePicker = (start, end) => {
		setDates({ start, end });
    }
	const setSecondTabDatePicker = (secondStart, secondEnd) => {
		setsecondTDates({ secondStart, secondEnd });
    }
	const setThirdDatePicker = (thirdStart, thirdEnd) => {
		setThirdDates({ thirdStart, thirdEnd });
    }
	const ttfooter = (tooltipItems) => {
		return 'Count of Invoice: ' + tooltipItems[0]['dataset']['footer'][tooltipItems[0]['dataIndex']];
	};

	// connections status
	const [status, setStatus] = useState(status_opt[0].value);
	const [basedonCollection,setBasedonCollection] = useState(basedOnCollection_opt[0].value);
	// const [basedonCollection,setBasedonCollection] = useState(basedOnCollection_opt[0].value);
	//const [paymentMode,setPaymentMode] = useState(["Cash","UPI/QR","Mobile Wallets","Cheque/DD","neft/rtgs/imps","Netbanking","EMI","BNPL","Credit/Debit Card"]);
	const paymentMode = ["Cash","UPI/QR","Mobile Wallets","Cheque/DD","neft/rtgs/imps","Netbanking","EMI","BNPL","Credit/Debit Card"];
	const [isDueSelected, setIsDueSelected] = useState(true);
	const [plainData, setPlainData] = useState([]);
	const [customerData, setCustomerData] = useState([]);
	const [creatorData, setCreatorData] = useState([]);
	const [paymentModeData, setPaymentModeData] = useState([]);
	const [tabSelected, setTabSelected] = useState('sales');
	const [defaultVal,setDefaultVal] = useState(basedOnCollection_opt[0]);

	const [grandTotal, setGrandTotal] = useState(0);
	const [totalInvCount, setTotalInvCount] = useState(0);
	// sales
	const [totalParticularsCount, setTotalParticularsCount] = useState(0);
	const [expandTable, setExpandTable] = useState(false);
	const [othersData, setOthersData] = useState([]);
	const [collectionOthersData, setCollectionOthersData] = useState([]);
	const [exportModal,setExportModal] = React.useState(false);
	const [dlParams,setDlParams] = React.useState([]);

	// collections total row states
	const [dueDebtorGrandTotal, setDueDebtorGrandTotal] = useState(0)
	//const [dueDebtorParticularsTotal, setDueDebtorParticularsTotal] = useState(0)
	const [dueDebtorInvoiceTotal, setDueDebtorInvoiceTotal] = useState(0)
	
	const [dueCustomerGrandTotal, setDueCustomerGrandTotal] = useState(0)
	const [dueCustomerParticularsTotal, setDueCustomerParticularsTotal] = useState(0)
	const [dueCustomerInvoiceTotal, setDueCustomerInvoiceTotal] = useState(0)
	
	const [dueCreatorGrandTotal, setDueCreatorGrandTotal] = useState(0)
	const [dueCreatorParticularsTotal, setDueCreatorParticularsTotal] = useState(0)
	const [dueCreatorInvoiceTotal, setDueCreatorInvoiceTotal] = useState(0)
	
	const [collectedCreatorGrandTotal, setCollectedCreatorGrandTotal] = useState(0)
	const [collectedCreatorParticularsTotal, setCollectedCreatorParticularsTotal] = useState(0)
	const [collectedCreatorInvoiceTotal, setCollectedCreatorInvoiceTotal] = useState(0)
	
	const [collectedCustomerGrandTotal, setCollectedCustomerGrandTotal] = useState(0)
	const [collectedCustomerParticularsTotal, setCollectedCustomerParticularsTotal] = useState(0)
	const [collectedCustomerInvoiceTotal, setCollectedCustomerInvoiceTotal] = useState(0)

	const [collectedBankAccountGrandTotal, setCollectedBankAccountGrandTotal] = useState(0)
	const [collectedBankAccountParticularsTotal, setCollectedBankAccountParticularsTotal] = useState(0)
	const [collectedBankAccountInvoiceTotal, setCollectedBankAccountInvoiceTotal] = useState(0)
	const [bankAccountData, setBankAccountData] = useState([]);

	const [otherIncomeGrandTotal, setOtherIncomeGrandTotal] = useState(0)
	const [otherIncomeParticularsTotal, setOtherIncomeParticularsTotal] = useState(0)
	const [otherIncomeInvoiceTotal, setOtherIncomeInvoiceTotal] = useState(0)
	const [otherIncomeData, setOtherIncomeData] = useState([]);
	const [otherIncomeOtherData, setOtherIncomeOtherData] = useState([]);

	const chart_options = {
		responsive: true,
		plugins: {
			legend : {
				position: 'top'
			},
			tooltip: {
				callbacks: {
					footer: ttfooter,
				}
			}
		}
	}
	/*const pie_data = {
		labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
		datasets: [
			{
			  label: '# of Votes',
			  data: [12, 19, 3, 5, 2, 3],
			  backgroundColor: [
				'rgba(255, 99, 132, 0.2)',
				'rgba(54, 162, 235, 0.2)',
				'rgba(255, 206, 86, 0.2)',
				'rgba(75, 192, 192, 0.2)',
				'rgba(153, 102, 255, 0.2)',
				'rgba(255, 159, 64, 0.2)',
			  ],
			  borderColor: [
				'rgba(255, 99, 132, 1)',
				'rgba(54, 162, 235, 1)',
				'rgba(255, 206, 86, 1)',
				'rgba(75, 192, 192, 1)',
				'rgba(153, 102, 255, 1)',
				'rgba(255, 159, 64, 1)',
			  ],
			  borderWidth: 1,
			},
		],
	};*/
	const genChart = () => {
		Common.axios({
			method: 'post',
			url:props.url+'dashboard/chart',
			data: {pid:Common.AES.encrypt(localStorage.getItem('fo_profileid'), props.site_key).toString(),'basedon':basedon,'docstatus':docstatus,'doctype':doctype,'dates':dates}
		}).then(function (response) {
			if(response.data.success){
				setGrandTotal(response.data.sale_grand_total);
				setTotalInvCount(response.data.sale_total_inv_count);
				setTotalParticularsCount(response.data.sale_total_particulars_count);
				setOthersData(response.data.sale_other_data);
				setPieData(response.data.data);
				setSaleData(response.data.plain);
				return false;
			}
			Common.toast.error(response.data.message);
		})
	}
	const genCollectionsChart = () => {
		Common.axios({
			method: 'post',
			url:props.url+'dashboard/collectionChart',
			data: {pid:Common.AES.encrypt(localStorage.getItem('fo_profileid'), props.site_key).toString(),'status':status,'basedonCollection':basedonCollection,'paymentMode':paymentMode,'dates':dates,'second_dates':secondTDates,'third_dates':thirdDates}
		}).then(function (response) {
			if(response.data.success){
				if (status === 'Dues' && basedonCollection == 'Debtor Days') {
					setDueDebtorGrandTotal(response.data.grand_total);
					//setDueDebtorParticularsTotal(response.data.total_particulars_count);
					setDueDebtorInvoiceTotal(response.data.total_inv_count);
				} else if (status === 'Dues' && basedonCollection == 'Customer') {
					setDueCustomerGrandTotal(response.data.grand_total);
					setDueCustomerParticularsTotal(response.data.total_particulars_count);
					setDueCustomerInvoiceTotal(response.data.total_inv_count);
				} else if (status === 'Dues' && basedonCollection === 'Creator') {
					setDueCreatorGrandTotal(response.data.grand_total);
					setDueCreatorParticularsTotal(response.data.total_particulars_count);
					setDueCreatorInvoiceTotal(response.data.total_inv_count);
				} else if (status === 'Collected' && basedonCollection === 'Creator') {
					setCollectedCreatorGrandTotal(response.data.grand_total);
					setCollectedCreatorParticularsTotal(response.data.total_particulars_count);
					setCollectedCreatorInvoiceTotal(response.data.total_inv_count);
				} else if (status === 'Collected' && basedonCollection === 'Customer') {
					setCollectedCustomerGrandTotal(response.data.grand_total);
					setCollectedCustomerParticularsTotal(response.data.total_particulars_count);
					setCollectedCustomerInvoiceTotal(response.data.total_inv_count);
					
				} else if (status === 'Collected' && basedonCollection === 'PaymentMode') {
					setCollectedCustomerGrandTotal(response.data.grand_total);
					setCollectedCustomerParticularsTotal(response.data.total_particulars_count);
					setCollectedCustomerInvoiceTotal(response.data.total_inv_count);
					
				} else if (status === 'Collected' && basedonCollection === 'BankAccount') {
					setCollectedBankAccountGrandTotal(response.data.grand_total);
					setCollectedBankAccountParticularsTotal(response.data.total_particulars_count);
					setCollectedBankAccountInvoiceTotal(response.data.total_inv_count);
				}
				setPieCollectionData(response.data.data);
				setCollectionOthersData(response.data.other_data);
				/* setGrandTotal(response.data.grand_total);
				setTotalInvCount(response.data.total_inv_count);
				setTotalParticularsCount(response.data.total_particulars_count); */
				if (basedonCollection === 'Debtor Days')
					setPlainData(response.data.plain);
				else if (basedonCollection === 'Customer')
					setCustomerData(response.data.plain);
				else if (basedonCollection === 'Creator')
					setCreatorData(response.data.plain);
				else if (basedonCollection === 'PaymentMode')
					setPaymentModeData(response.data.plain);
				else
					setBankAccountData(response.data.plain);
				return false;
			}
			Common.toast.error(response.data.message);
		})
	}
	const getOtherIncomeChart = () => {
		Common.axios({
			method: 'post',
			url:props.url+'dashboard/otherIncomeChart',
			data: {pid:Common.AES.encrypt(localStorage.getItem('fo_profileid'), props.site_key).toString(),'status':statusOther,'basedonOther':basedonOther,'dates':dates,'second_dates':secondTDates,'third_dates':thirdDates}
		}).then(function (response) {
			if(response.data.success){
				setPieCollectionData(response.data.data);
				setOtherIncomeGrandTotal(response.data.grand_total)
				setOtherIncomeParticularsTotal(response.data.total_particulars_count)
				setOtherIncomeInvoiceTotal(response.data.total_inv_count)
				setOtherIncomeData(response.data.plain)
				setOtherIncomeOtherData(response.data.other_data)
				return false;
			}
			Common.toast.error(response.data.message);
		})
	}
	// const setDoctypeCheckbox = val => {
	// 	if( val.length === 0 ){
	// 		Common.toast.error('At least 1 doc type should be selected.');
	// 		return false;
	// 	}
	// 	setDoctype(val);
	// }
	// const setPaymentModeCheckbox = val => {
	// 	if( val.length === 0 ){
	// 		Common.toast.error('At least 1 doc type should be selected.');
	// 		return false;
	// 	}
	// 	setPaymentMode(val);
	// }
	useEffect(() => {
		if( props.referrer === "fynone" ){
			document.title = 'Dashboard | '+props.site_name;
		}
		if( props.referrer === "fynmart" ){
			document.title = 'Mart Dashboard | '+props.site_name;
		}
		if (tabSelected === 'sales') {
			genChart();
		}
		if (tabSelected === 'collected') {
			genCollectionsChart();
		}
		if (tabSelected === 'other') {
			getOtherIncomeChart();
		}
	}, [props,basedon,docstatus,dates,secondTDates,thirdDates,status,basedonCollection,statusOther,basedonOther,tabSelected]);
	const handleTableExpand = () => {
		expandTable === false ? setExpandTable(true) : setExpandTable(false);
	}
	// dues
	const columns = [
        {
            name: 'Particulars',
            selector: row => row.labels,
            sortable: true,
			sortField: 'days',
			cell: (row) => (
				(row.labels!== undefined && row.labels.match(/Others /i)) ? <a href='#table-expand' onClick={handleTableExpand}>{row.labels}</a> : row.labels
			)
        },
        {
            name: 'Invoice Count',
            selector: row => row.inv_count,
            sortable: true,
			sortField: 'days',
			cell: (row) => (
				expandTable === true && row.labels.match(/Others /i) ? <></> : row.inv_count
			)
        },
		{
            name: 'Total value ⟨₹⟩',
            selector: row => row.total_val,
            sortable: true,
			sortField: 'path',
			cell: (row) => (
				expandTable === true && row.labels.match(/Others /i) ? <></> :row.total_val
			)
        }
    ];
    const collectedColumns = [
        {
            name: 'Particulars',
            selector: row => row.labels,
            sortable: true,
			sortField: 'days',
			cell: (row) => (
				row.labels.match(/Others /i) ? <a href='#table-expand' onClick={handleTableExpand}>{row.labels}</a> : row.labels
			)
        },
        {
            name: 'Payment Count',
            selector: row => row.inv_count,
            sortable: true,
			sortField: 'days',
			cell: (row) => (
				expandTable === true && row.labels.match(/Others /i) ? <></> : row.inv_count
			)
        },
		{
            name: 'Total value ⟨₹⟩',
            selector: row => row.total_val,
            sortable: true,
			sortField: 'path',
			cell: (row) => (
				expandTable === true && row.labels.match(/Others /i) ? <></> : row.total_val
			)
        }
    ];
    const saleColumns = [
        {
            name: 'Particulars',
            selector: row => row.name,
            sortable: true,
			sortField: 'days',
			cell: (row) => (
				(row.name !== undefined && row.name.match(/Others /i)) ? <a href='#table-expand' onClick={handleTableExpand}>{row.name}</a> : row.name
			)
        },
        {
            name: 'Invoice Count',
            selector: row => row.count,
            sortable: true,
			sortField: 'days',
			cell: (row) => (
				expandTable === true && row.name.match(/Others /i) ? <></> : row.count
			)
        },
		{
            name: 'Total value ⟨₹⟩',
            selector: row => row.value,
            sortable: true,
			sortField: 'path',
			cell: (row) => (
				expandTable === true && row.name.match(/Others /i) ? <></> :row.value
			)
        }
    ];

	const othIncomeColumns = [
        {
            name: 'Particulars',
            selector: row => row.labels,
            sortable: true,
			sortField: 'days',
			cell: (row) => (
				(row.labels !== undefined && row.labels.match(/Others /i)) ? <a href='#table-expand' onClick={handleTableExpand}>{row.labels}</a> : row.labels
			)
        },
        {
            name: 'Other Income Count',
            selector: row => row.inv_count,
            sortable: true,
			sortField: 'days',
			cell: (row) => (
				expandTable === true && row.labels.match(/Others /i) ? <></> : row.inv_count
			)
        },
		{
            name: 'Total value ⟨₹⟩',
            selector: row => row.total_val,
            sortable: true,
			sortField: 'path',
			cell: (row) => (
				expandTable === true && row.labels.match(/Others /i) ? <></> : row.total_val
			)
        }
    ];
	
	const handleSalesTabChange = () =>{
		setTabSelected('sales');
		setExpandTable(false);
	}
	const handleCollectionTabChange = () =>{
		setTabSelected('collected');
		setExpandTable(false);
	}
	const handleOtherTabChange = () => {
		setTabSelected('other');
		setExpandTable(true);
		setSaleData([]);
		setCustomerData([]);
		setCreatorData([]);
		setPaymentModeData([]);
		setBankAccountData([]);
	}

	const handleBasedOnChange = (basedOnColl) => {
		setBasedonCollection(basedOnColl);
		if (basedOnColl == 'Debtor Days' && status === 'Dues') {
		    setDefaultVal(basedOnCollection_opt[0])
		} else if (basedOnColl == 'Customer' && status === 'Dues') {
		    setDefaultVal(basedOnCollection_opt[1])
		} else if (basedOnColl == 'Creator' && status === 'Dues') {
		    setDefaultVal(basedOnCollection_opt[2])
		} else if (basedOnColl == 'Customer' && status === 'Collected') {
		    setDefaultVal(basedOnCollection_collected_select_opt[0])
		} else if (basedOnColl == 'Creator' && status === 'Collected') {
		    setDefaultVal(basedOnCollection_collected_select_opt[1])
		} else if (basedOnColl == 'PaymentMode' && status === 'Collected') {
		    setDefaultVal(basedOnCollection_collected_select_opt[2])
		} else if (basedOnColl == 'BankAccount' && status === 'Collected') {
		    setDefaultVal(basedOnCollection_collected_select_opt[3])
		}
	}
	const handleOtherBasedOnChange = (othBasedOn) => {
		setBasedonOther(othBasedOn)
		if (othBasedOn === 'oth_inc_type' && statusOther === 'Dues') {
			setDefaultValOth(basedon_opt_oth[1])
		} else if (othBasedOn === 'Creator' && statusOther === 'Dues') {
			setDefaultValOth(basedon_opt_oth[0])
		} else if (othBasedOn === 'oth_inc_type_collected' && statusOther === 'Collected') {
			setDefaultValOth(basedon_opt_oth_collected[0])
		}
	}
	// const checkAllItems = event => {
	// 	if(event.target.checked){
	// 		setDlParams(checkboxes);
	// 		setCheckAll(true);
	// 	} else {
	// 		setDlParams([]);
	// 		setCheckAll(false);
	// 	}
	// }
	const setDownloadParams = event => {
        if(event.target.checked){
            setDlParams([...dlParams, event.currentTarget.value]);
        } else {
            setDlParams(dlParams.filter(item=>item !== event.currentTarget.value));
        }
    }
	const handleCSVExport = (e) => {
		e.preventDefault();
		let exportUrl = '';
		if (tabSelected === 'collected') {
			if (basedonCollection === 'Debtor Days' && status === 'Dues') {
				exportUrl = 'dashboard/downloadDueDebtor';
			} else if (basedonCollection === 'Customer' && status === 'Dues') {
				exportUrl = 'dashboard/downloadDueCustomer';
			} else if (basedonCollection === 'Creator' && status === 'Dues') {
				exportUrl = 'dashboard/downloadDueCreator';
			} else if (basedonCollection === 'Creator' && status === 'Collected') {
				exportUrl = 'dashboard/downloadCollectedCreator';
			} else if (basedonCollection === 'Customer' && status === 'Collected') {
				exportUrl = 'dashboard/downloadCollectedCustomer';
			} else if (basedonCollection === 'PaymentMode' && status === 'Collected') {
				exportUrl = 'dashboard/downloadCollectedPaymentMode';
			} else if (basedonCollection === 'BankAccount' && status === 'Collected') {
				exportUrl = 'dashboard/downloadCollectedBankAccount';
			}
			Common.axios({
				method: 'post',
				url:props.url+exportUrl,
				data: {
					pid:Common.AES.encrypt(localStorage.getItem('fo_profileid'), props.site_key).toString(),
					'status':status,
					'basedonCollection':basedonCollection,
					'paymentMode':paymentMode,
					'dates':dates,
					'dlParams':dlParams
				},
				// responseType: 'blob',
			}).then(function (response) {
				if(response.data.success){
					window.location.href = response.data.message;
					/* const url = window.URL.createObjectURL(new Blob([response.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', `Dashboard-${Date.now()}.csv`);
					document.body.appendChild(link); */
					setExportModal(false);
					setCheckAll(false);
					setDlParams([]);
					// link.click();
				} else{
					Common.toast.error(response.data.message);
				}
			}).catch(error => console.log(error));
		} else if (tabSelected === 'other') {
			/* if (statusOther === 'Dues' && basedonOther === 'Creator') {
				exportUrl = 'dashboard/downloadOtherIncomeDataByCreator'				
			} else if (statusOther === 'Dues' && basedonOther === 'oth_inc_type') {
				exportUrl = 'dashboard/downloadOtherIncomeDataByIncomeType'
			} else {
				exportUrl = 'dashboard/downloadOtherIncomeDataByIncomeTypeCollected'
			} */
			Common.axios({
				method: 'post',
				url:props.url+'dashboard/downloadOtherIncomeDataByCreator',
				data: {
					pid:Common.AES.encrypt(localStorage.getItem('fo_profileid'), props.site_key).toString(),
					'status':statusOther,
					'basedonOther':basedonOther,
					'dates':dates,
					'dlParams':dlParams
				},
			}).then(function (response) {
				if(response.data.success){
					window.location.href = response.data.message;
					setExportModal(false);
					setCheckAll(false);
					setDlParams([]);
				} else{
					Common.toast.error(response.data.message);
				}
			}).catch(error => console.log(error));
		} else {
			exportUrl = 'dashboard/downloadSaleData';
			Common.axios({
				method: 'post',
				url:props.url+exportUrl,
				data: {
					pid:Common.AES.encrypt(localStorage.getItem('fo_profileid'), props.site_key).toString(),
					'basedon':basedon,
					'docstatus':docstatus,
					'doctype':doctype,
					'dates':dates,
					'dlParams':dlParams
				},
				// responseType: 'blob',
			}).then(function (response) {
				if(response.data.success){
					window.location.href = response.data.message;
					setExportModal(false);
					setCheckAll(false);
					setDlParams([]);
					/* const url = window.URL.createObjectURL(new Blob([response.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', `Dashboard-${Date.now()}.csv`);
					document.body.appendChild(link);
					link.click(); */
				} else{
					Common.toast.error(response.data.message);
				}
			}).catch(error => console.log(error));
		}
		
	}
	const handleSelectDocTypeEvent = (option) => {
		setBasedon(option);
	}
    return (
        <div className="layout-wrapper layout-menu-fixed layout-content-navbar">
            <div className="layout-container">
				{props.referrer === 'fynone' ? 
                <Layout site_logo={props.site_logo} label="dashboard" site={props.site_name}/>
				:
				<MartLayout label="mart-dashboard" {...props}/>
				}
                <div className="layout-page">
					{props.referrer === 'fynone' ? 
                    <NavBar site={props.site_name} url={props.url}/>
					:
					<MartNavBar {...props}/>
					}
					<div className="content-wrapper">
						<div className="container-xxl flex-grow-1 container-p-y">
							<h4 className="fw-bold py-3 mb-3">{props.referrer === 'fynone' && (<><span className="text-muted fw-light">Income & Collections /</span></>)} Dashboard
								<span className="dropdown">
                                    <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className='text-danger bx bxl-youtube bx-md'></i>
                                    </button>
                                    <div className="dropdown-menu" >
									{props.referrer === 'fynmart' && <>
                                        <a href="https://youtu.be/rgYtDBgTN4E" className="dropdown-item" target="_blank" rel="noreferrer"><span>Dashboard overview</span></a>
									</>}
									{props.referrer === 'fynone' && <>
                                        <a href="https://youtu.be/vMSNAR4_H3s" className="dropdown-item" target="_blank" rel="noreferrer"><span>Income dashboard</span></a>
									</>}
                                    </div>
								</span>
							</h4>
							<div className="row">
								<div className="nav-align-top mb-4">
									<ul className="nav nav-tabs nav-fill" role="tablist">
										<li className="nav-item">
											<button type="button" className="nav-link active" role="tab" data-bs-toggle="tab" data-bs-target="#navs-justified-sale" aria-controls="navs-justified-home" aria-selected="true" onClick={handleSalesTabChange}>
											Top Sale Invoices (by value)
											</button>
										</li>
										<li className="nav-item">
											<button type="button" className="nav-link" role="tab" data-bs-toggle="tab" data-bs-target="#navs-justified-collection" aria-controls="navs-justified-profile" aria-selected="false" onClick={handleCollectionTabChange}>
											Top Collections (by value)
											</button>
										</li>
										{props.referrer === 'fynone' && (<>
										<li className="nav-item">
											<button type="button" className="nav-link" role="tab" data-bs-toggle="tab" data-bs-target="#navs-justified-other" aria-controls="navs-justified-profile" aria-selected="false" onClick={handleOtherTabChange}>
											Top Other Income (by value)
											</button>
										</li>
										</>)}
									</ul>
									<div className="tab-content">
										<div className="tab-pane fade show active" id="navs-justified-sale" role="tabpanel">
											<div className="row justify-content-md-center">
												<div className="col-sm-4 mb-2">
													<div className="row">
														<label className="col-md-6 col-form-label">Document Status</label>
														<div className="col-md-6">
															<Select id="doc_status" defaultValue={doc_statuses[0]} options={doc_statuses} onChange={(e) => setDocStatus(e.value)}/>
														</div>
													</div>
												</div>
												<div className="col-sm-4 mb-2">
													<div className="row">
														<label className="col-md-4 col-form-label">Based On</label>
														{/* setBasedon(e.value) */}
														<div className="col-md-8">
															<Select id="basedon" defaultValue={basedon_opt[0]} options={basedon_opt} onChange={(e) => {handleSelectDocTypeEvent(e.value)}}/>
														</div>
													</div>
												</div>
												<div className="col-sm-4 mb-2">
												<div className="row">
														<label className="col-md-3 col-form-label">Date Range</label>
														<div className="col-md-9 text-center">
															<DateRangePicker
																initialSettings={DPE.DatePickerPredefined(moment,start,end)}
																onCallback={setDatePicker}>
																<div className="input-group">
																	<span title="Date Range" className="input-group-text"><i className='bx bxs-calendar'></i></span>
																	<div id="reportrange" className="form-control form-control-sm" style={{ cursor: 'pointer',padding:'5px 0px',fontSize:'small'}}>
																		<i className="fa fa-calendar"></i>&nbsp;<span>{label}</span> <i className="fa fa-caret-down"></i>
																	</div>
																</div>
															</DateRangePicker>
															<small>Date range based on Invoice Date</small>
														</div>
													</div>
													<div className="row">
															{/* <label className="col-md-4 col-form-label">Doc Type</label> */}
															{/* <div className="col-md-8"> */}
															{/*<Select hideSelectedOptions={false} isMulti id="doc_type" isClearable={false} defaultValue={[doc_types[0], doc_types[1], doc_types[2]]} options={doc_types} onChange={(e) => setDoctypeCheckbox(e.value)}/>*/}
																{/* <MultiSelect filter value={doctype} onChange={(e) => setDoctypeCheckbox(e.value)} maxSelectedLabels={1} options={doc_types} optionLabel="label" placeholder="Select" className="d-flex" panelClassName="fomulti"/> */}
															{/* </div> */}
														{/*<div className="col-md-9">
															<div className="form-check form-check-inline mt-1">
																<input className="form-check-input" type="checkbox" id="doct_inv" value="INV" checked={doctype.includes('INV')} disabled={doctype.includes('INV') && doctype.length === 1} onChange={setDoctypeCheckbox}/>
																<label className="form-check-label" htmlFor="doct_inv">Invoice</label>
															</div>
															<div className="form-check form-check-inline mt-1">
																<input className="form-check-input" type="checkbox" id="doct_cn" value="CN" checked={doctype.includes('CN')} disabled={doctype.includes('CN') && doctype.length === 1} onChange={setDoctypeCheckbox}/>
																<label className="form-check-label" htmlFor="doct_cn">Credit Note</label>
															</div>
															<div className="form-check form-check-inline mt-1">
																<input className="form-check-input" type="checkbox" id="doct_dn" value="DN" checked={doctype.includes('DN')} disabled={doctype.includes('DN') && doctype.length === 1} onChange={setDoctypeCheckbox}/>
																<label className="form-check-label" htmlFor="doct_dn">Debit Note</label>
															</div>
														</div>*/}
													</div>
												</div>
												<div className="col-sm-5 mb-2">
													
												</div>
											</div>
											<div className="row justify-content-center">
												<div className="col-sm-6 mt-5">
													{(Object.keys(pieData).length > 0) && <Pie options={chart_options} data={pieData} />}
													{(Object.keys(pieData).length === 0) && <div className="text-center alert alert-warning" role="alert">No data available</div>}
												</div>
											</div>
										</div>
										<div className="tab-pane fade" id="navs-justified-collection" role="tabpanel">
											<div className="row justify-content-md-center">
												<div className="col-sm-4 mb-2">
													<div className="row">
														<label className="col-md-4 col-form-label">Status</label>
														<div className="col-md-8">
															<Select id="status" defaultValue={status_opt[0]} options={status_opt} onChange={(e) => {setStatus(e.value); e.value === 'Dues' ? setDefaultVal(basedOnCollection_opt[0]): setDefaultVal(basedOnCollection_collected_select_opt[0]); e.value === 'Dues' ? setBasedonCollection('Debtor Days') : setBasedonCollection('Customer'); e.value === 'Dues' ? setIsDueSelected(true):setIsDueSelected(false)}}/>
														</div>
													</div>
												</div>
												<div className="col-sm-4 mb-2">
													<div className="row">
														<label className="col-md-4 col-form-label">Based On</label>
														<div className="col-md-8">
															<Select id="basedon_collection" defaultValue={status === 'Collected' ? basedOnCollection_collected_select_opt[0] : basedOnCollection_opt[0]} value={defaultVal} options={status === 'Collected' ? basedOnCollection_collected_select_opt : basedOnCollection_opt} onChange={(e) => {handleBasedOnChange(e.value);}}/>
														</div>
													</div>
												</div>
												<div className="col-sm-4 mb-2">
												<div className={isDueSelected === true ? "row d-none" : "row"}>
														<label className="col-md-3 col-form-label">Date Range</label>
														<div className="col-md-9 text-center">
															<DateRangePicker
																initialSettings={DPE.DatePickerPredefined(moment,secondStart,secondEnd)}
																onCallback={setSecondTabDatePicker}>
																<div className="input-group">
																	<span title="Date Range" className="input-group-text"><i className='bx bxs-calendar'></i></span>
																	<div id="reportrange" className="form-control form-control-sm" style={{ cursor: 'pointer',padding:'5px 0px',fontSize:'small'}}>
																		<i className="fa fa-calendar"></i>&nbsp;<span>{secondlabel}</span> <i className="fa fa-caret-down"></i>
																	</div>
																</div>
															</DateRangePicker>
															<small>Date range based on Payment Date</small>
														</div>
													</div>
													{/* <div className={isDueSelected === true ? "row d-none" : "row"}>
														<label className="col-md-4 col-form-label">Payment Mode</label>
														<div className="col-md-8">
															<MultiSelect filter value={paymentMode} onChange={(e) => setPaymentModeCheckbox(e.value)} maxSelectedLabels={1} options={payment_mode_opt} optionLabel="label" placeholder="Select" className="d-flex" panelClassName="fomulti"/>
														</div>
													</div> */}
												</div>
												<div className="col-sm-5 mb-2">
													
												</div>
											</div>
											<div className="row justify-content-center">
												<div className="col-sm-6 mt-5">
													{(Object.keys(pieCollectionData).length > 0) && <Pie options={chart_options} data={pieCollectionData} />}
													{(Object.keys(pieCollectionData).length === 0) && <div className="text-center alert alert-warning" role="alert">No data available</div>}
												</div>
											</div>
										</div>
										<div className="tab-pane fade" id="navs-justified-other" role="tabpanel">
											<div className="row justify-content-md-center">
												<div className="col-sm-4 mb-2">
													<div className="row">
														<label className="col-md-4 col-form-label">Status</label>
														<div className="col-md-8">
															<Select id="status" defaultValue={status_opt_oth[0]} options={status_opt_oth} onChange={(e) => {setStatusOther(e.value); e.value === 'Dues' ? setDefaultValOth(basedon_opt_oth[0]): setDefaultValOth(basedon_opt_oth_collected[0]); e.value === 'Dues' ? setBasedonOther('Creator') : setBasedonOther('oth_inc_type_collected'); e.value === 'Dues' ? setIsDueSelectedOth(true):setIsDueSelectedOth(false)}}/>
														</div>
													</div>
												</div>
												<div className="col-sm-4 mb-2">
													<div className="row">
														<label className="col-md-4 col-form-label">Based On</label>
														<div className="col-md-8">
															<Select id="basedon_collection" defaultValue={status === 'Dues' ? basedon_opt_oth[0] : basedon_opt_oth_collected[0]} value={defaultValOth} options={statusOther === 'Dues' ? basedon_opt_oth : basedon_opt_oth_collected} onChange={(e) => {handleOtherBasedOnChange(e.value);}}/>
														</div>
													</div>
												</div>
												<div className="col-sm-4 mb-2">
												<div className={isDueSelectedOth === true ? "row d-none" : "row"}>
														<label className="col-md-3 col-form-label">Date Range</label>
														<div className="col-md-9 text-center">
															<DateRangePicker
																initialSettings={DPE.DatePickerPredefined(moment,thirdStart,thirdEnd)}
																onCallback={setThirdDatePicker}>
																<div className="input-group">
																	<span title="Date Range" className="input-group-text"><i className='bx bxs-calendar'></i></span>
																	<div id="reportrange" className="form-control form-control-sm" style={{ cursor: 'pointer',padding:'5px 0px',fontSize:'small'}}>
																		<i className="fa fa-calendar"></i>&nbsp;<span>{thirdlabel}</span> <i className="fa fa-caret-down"></i>
																	</div>
																</div>
															</DateRangePicker>
															<small>Date range based on Payment Date</small>
														</div>
													</div>
												</div>
												<div className="col-sm-5 mb-2">
													
												</div>
											</div>
											<div className="row justify-content-center">
												<div className="col-sm-6 mt-5">
													{(Object.keys(pieCollectionData).length > 0) && <Pie options={chart_options} data={pieCollectionData} />}
													{(Object.keys(pieCollectionData).length === 0) && <div className="text-center alert alert-warning" role="alert">No data available</div>}
												</div>
											</div>
										</div>
										{tabSelected === 'collected' ? <>
											{basedonCollection === 'Debtor Days' && status === 'Dues' ? <>
												{plainData ? <><button className="btn btn-sm btn-info me-2" onClick={(e)=>setExportModal(true)}><i className="bx bx-download"></i> <span className="hidden-phone">Export CSV</span></button></> : <></>}
												<DTE.DataTable className="rdt_AdjDrp" title={""} columns={columns}  data={plainData} paginationServer persistTableHead/>
												{pieCollectionData ? <>
													<hr/> 
													<table className="table table-lg total-table">
														<tbody className='table-border-bottom-0'>
															<tr>
																<td colSpan={3} style={{'width': '33%'}}><strong>Total </strong></td>
																<td colSpan={3} style={{'width': '33%'}}><strong>{dueDebtorInvoiceTotal}</strong></td>
																<td colSpan={3} style={{'width': '34%'}}><strong>{dueDebtorGrandTotal}</strong></td>
															</tr>
														</tbody>
													</table>
												</> : <></> }
											</>:<></>}
											
											{basedonCollection === 'Customer' && tabSelected === 'collected' && status === 'Dues' ? <>
												{customerData ? <><button className="btn btn-sm btn-info me-2" onClick={(e)=>setExportModal(true)}><i className="bx bx-download"></i> <span className="hidden-phone">Export CSV</span></button></> : <></>}
												<DTE.DataTable className="rdt_AdjDrp" title={""} columns={columns}  data={customerData}  paginationServer persistTableHead/>

												{collectionOthersData && expandTable === true ? <>
													<table className="table table-sm others-table-part">
														<tbody className='table-border-bottom-0'>
															{collectionOthersData.map((row, index) => (
																<tr key={index}>
																	<td  style={{'width': '31%'}}>
																		<span className='particular-name'>{row.labels}</span> 
																		</td>
																	<td style={{'width': '30%'}}>{row.inv_count}</td>
																	<td style={{'width': '30%'}}>{row.total_val}</td>
																</tr>
															))}
														</tbody>
													</table> 
												</> : <></>}

												{pieCollectionData ? <>
													<hr/> 
													<table className="table table-lg total-table">
														<tbody className='table-border-bottom-0'>
															<tr>
																<td colSpan={3} style={{'width': '33%'}}><strong>Total ({dueCustomerParticularsTotal}) </strong></td>
																<td colSpan={3} style={{'width': '33%'}}><strong>{dueCustomerInvoiceTotal}</strong></td>
																<td colSpan={3} style={{'width': '34%'}}><strong>{dueCustomerGrandTotal}</strong></td>
															</tr>
														</tbody>
													</table>
												</> : <></> }
											</>:<></>}

											{basedonCollection === 'Creator' && status === 'Dues' ? <>
												{creatorData ? <><button className="btn btn-sm btn-info me-2" onClick={(e)=>setExportModal(true)}><i className="bx bx-download"></i> <span className="hidden-phone">Export CSV</span></button></> : <></>}
												<DTE.DataTable className="rdt_AdjDrp" title={""} columns={columns}  data={creatorData}  paginationServer persistTableHead/>

												{collectionOthersData && tabSelected === 'collected' && expandTable === true ? <>
													<table className="table table-sm others-table-part">
														<tbody className='table-border-bottom-0'>
															{collectionOthersData.map((row, index) => (
																<tr key={index}>
																	<td  style={{'width': '31%'}}>
																		<span className='particular-name'>{row.labels}</span> 
																		</td>
																	<td style={{'width': '30%'}}>{row.inv_count}</td>
																	<td style={{'width': '30%'}}>{row.total_val}</td>
																</tr>
															))}
														</tbody>
													</table> 
												</> : <></>}
												

												{pieCollectionData ? <>
													<hr/> 
													<table className="table table-lg total-table">
														<tbody className='table-border-bottom-0'>
															<tr>
																<td colSpan={3} style={{'width': '33%'}}><strong>Total ({dueCreatorParticularsTotal}) </strong></td>
																<td colSpan={3} style={{'width': '33%'}}><strong>{dueCreatorInvoiceTotal}</strong></td>
																<td colSpan={3} style={{'width': '34%'}}><strong>{dueCreatorGrandTotal}</strong></td>
															</tr>
														</tbody>
													</table>
												</> : <></> }
											</>:<></>}

											{basedonCollection === 'Creator' && tabSelected === 'collected' && status === 'Collected' ? <>
												{creatorData ? <><button className="btn btn-sm btn-info me-2" onClick={(e)=>setExportModal(true)}><i className="bx bx-download"></i> <span className="hidden-phone">Export CSV</span></button></> : <></>}
												<DTE.DataTable className="rdt_AdjDrp" title={""} columns={collectedColumns}  data={creatorData}  paginationServer persistTableHead/>

												{collectionOthersData && expandTable === true ? <>
													<table className="table table-sm others-table-part">
														<tbody className='table-border-bottom-0'>
															{collectionOthersData.map((row, index) => (
																<tr key={index}>
																	<td  style={{'width': '31%'}}>
																		<span className='particular-name'>{row.labels}</span> 
																		</td>
																	<td style={{'width': '30%'}}>{row.inv_count}</td>
																	<td style={{'width': '30%'}}>{row.total_val}</td>
																</tr>
															))}
														</tbody>
													</table> 
												</> : <></>}

												{pieCollectionData ? <>
													<hr/> 
													<table className="table table-lg total-table">
														<tbody className='table-border-bottom-0'>
															<tr>
																<td colSpan={3} style={{'width': '33%'}}><strong>Total ({collectedCreatorParticularsTotal}) </strong></td>
																<td colSpan={3} style={{'width': '33%'}}><strong>{collectedCreatorInvoiceTotal}</strong></td>
																<td colSpan={3} style={{'width': '34%'}}><strong>{collectedCreatorGrandTotal}</strong></td>
															</tr>
														</tbody>
													</table>
												</> : <></> }
											</>:<></>}

											{basedonCollection === 'Customer' && tabSelected === 'collected' && status === 'Collected' ? <>
												{customerData ? <><button className="btn btn-sm btn-info me-2" onClick={(e)=>setExportModal(true)}><i className="bx bx-download"></i> <span className="hidden-phone">Export CSV</span></button></> : <></>} 
												<DTE.DataTable className="rdt_AdjDrp" title={""} columns={collectedColumns}  data={customerData}  paginationServer persistTableHead/>

												{collectionOthersData && expandTable === true ? <>
													<table className="table table-sm others-table-part">
														<tbody className='table-border-bottom-0'>
															{collectionOthersData.map((row, index) => (
																<tr key={index}>
																	<td  style={{'width': '31%'}}>
																		<span className='particular-name'>{row.labels}</span> 
																		</td>
																	<td style={{'width': '30%'}}>{row.inv_count}</td>
																	<td style={{'width': '30%'}}>{row.total_val}</td>
																</tr>
															))}
														</tbody>
													</table> 
												</> : <></>}

												{pieCollectionData ? <>
													<hr/> 
													<table className="table table-lg total-table">
														<tbody className='table-border-bottom-0'>
															<tr>
																<td colSpan={3} style={{'width': '33%'}}><strong>Total ({collectedCustomerParticularsTotal}) </strong></td>
																<td colSpan={3} style={{'width': '33%'}}><strong>{collectedCustomerInvoiceTotal}</strong></td>
																<td colSpan={3} style={{'width': '34%'}}><strong>{collectedCustomerGrandTotal}</strong></td>
															</tr>
														</tbody>
													</table>
												</> : <></> }
											</>:<></>}
											{basedonCollection === 'PaymentMode' && tabSelected === 'collected' && status === 'Collected' ? <>
												{paymentModeData ? <><button className="btn btn-sm btn-info me-2" onClick={(e)=>setExportModal(true)}><i className="bx bx-download"></i> <span className="hidden-phone">Export CSV</span></button></> : <></>}
												<DTE.DataTable className="rdt_AdjDrp" title={""} columns={collectedColumns}  data={paymentModeData}  paginationServer persistTableHead/>

												{collectionOthersData && expandTable === true ? <>
													<table className="table table-sm others-table-part">
														<tbody className='table-border-bottom-0'>
															{collectionOthersData.map((row, index) => (
																<tr key={index}>
																	<td  style={{'width': '31%'}}>
																		<span className='particular-name'>{row.labels}</span> 
																		</td>
																	<td style={{'width': '30%'}}>{row.inv_count}</td>
																	<td style={{'width': '30%'}}>{row.total_val}</td>
																</tr>
															))}
														</tbody>
													</table> 
												</> : <></>}

												{pieCollectionData ? <>
													<hr/> 
													<table className="table table-lg total-table">
														<tbody className='table-border-bottom-0'>
															<tr>
																<td colSpan={3} style={{'width': '33%'}}><strong>Total ({collectedCustomerParticularsTotal}) </strong></td>
																<td colSpan={3} style={{'width': '33%'}}><strong>{collectedCustomerInvoiceTotal}</strong></td>
																<td colSpan={3} style={{'width': '34%'}}><strong>{collectedCustomerGrandTotal}</strong></td>
															</tr>
														</tbody>
													</table>
												</> : <></> }
											</>:<></>}
											{basedonCollection === 'BankAccount' && tabSelected === 'collected' && status === 'Collected' ? <>
												{bankAccountData ? <><button className="btn btn-sm btn-info me-2" onClick={(e)=>setExportModal(true)}><i className="bx bx-download"></i> <span className="hidden-phone">Export CSV</span></button></> : <></>}
												<DTE.DataTable className="rdt_AdjDrp" title={""} columns={collectedColumns}  data={bankAccountData}  paginationServer persistTableHead/>

												{collectionOthersData && expandTable === true ? <>
													<table className="table table-sm others-table-part">
														<tbody className='table-border-bottom-0'>
															{collectionOthersData.map((row, index) => (
																<tr key={index}>
																	<td  style={{'width': '31%'}}>
																		<span className='particular-name'>{row.labels}</span> 
																		</td>
																	<td style={{'width': '30%'}}>{row.inv_count}</td>
																	<td style={{'width': '30%'}}>{row.total_val}</td>
																</tr>
															))}
														</tbody>
													</table> 
												</> : <></>}

												{pieCollectionData ? <>
													<hr/> 
													<table className="table table-lg total-table">
														<tbody className='table-border-bottom-0'>
															<tr>
																<td colSpan={3} style={{'width': '33%'}}><strong>Total ({collectedBankAccountParticularsTotal}) </strong></td>
																<td colSpan={3} style={{'width': '33%'}}><strong>{collectedBankAccountInvoiceTotal}</strong></td>
																<td colSpan={3} style={{'width': '34%'}}><strong>{collectedBankAccountGrandTotal}</strong></td>
															</tr>
														</tbody>
													</table>
												</> : <></> }
											</>:<></>}
										</>:
											tabSelected === 'other' ? <>
												{otherIncomeData ? <><button className="btn btn-sm btn-info me-2" onClick={(e)=>setExportModal(true)}><i className="bx bx-download"></i> <span className="hidden-phone">Export CSV</span></button></> : <></>}
												<DTE.DataTable className="rdt_AdjDrp" title={""} columns={othIncomeColumns}  data={otherIncomeData} paginationServer persistTableHead/>
												{otherIncomeOtherData && expandTable === true ? <>
													<table className="table table-sm others-table-part">
														<tbody className='table-border-bottom-0'>
															{otherIncomeOtherData.map((row, index) => (
																<tr key={index}>
																	<td  style={{'width': '31%'}}>
																		<span className='particular-name'>{row.labels}</span> 
																		</td>
																	<td style={{'width': '30%'}}>{row.inv_count}</td>
																	<td style={{'width': '30%'}}>{row.total_val}</td>
																</tr>
															))}
														</tbody>
													</table> 
												</> : <></>}
												{pieCollectionData ? <>
													<hr/> 
													<table className="table table-lg total-table">
														<tbody className='table-border-bottom-0'>
															<tr>
																<td colSpan={3} style={{'width': '33%'}}><strong>Total ({otherIncomeParticularsTotal}) </strong></td>
																<td colSpan={3} style={{'width': '33%'}}><strong>{otherIncomeInvoiceTotal}</strong></td>
																<td colSpan={3} style={{'width': '34%'}}><strong>{otherIncomeGrandTotal}</strong></td>
															</tr>
														</tbody>
													</table>
												</> : <></> }
											</>:
										<>
											{saleData ? <><button className="btn btn-sm btn-info me-2" onClick={(e)=>setExportModal(true)}><i className="bx bx-download"></i> <span className="hidden-phone">Export CSV</span></button></> : <></>}
											<DTE.DataTable className="rdt_AdjDrp" title={""} columns={saleColumns}  data={saleData}  paginationServer persistTableHead/>
										</>}
										{othersData && tabSelected === 'sales' && expandTable === true ? <>
											<table className="table table-sm others-table-part">
												<tbody className='table-border-bottom-0'>
													{othersData.map((row, index) => (
														<tr key={index}>
															<td  style={{'width': '31%'}}>
																<span className='particular-name'>{row.name}</span> 
																</td>
															<td style={{'width': '30%'}}>{row.count}</td>
															<td style={{'width': '30%'}}>{row.value}</td>
														</tr>
													))}
												</tbody>
											</table> 
										</> : <></>}
										{saleData && tabSelected === 'sales' ? <>
											<hr/> 
											
											<table className="table table-lg total-table">
												<tbody className='table-border-bottom-0'>
													<tr>
														<td colSpan={3} style={{'width': '33%'}}><strong>Total ({totalParticularsCount})</strong></td>
														<td colSpan={3} style={{'width': '33%'}}><strong>{totalInvCount}</strong></td>
														<td colSpan={3} style={{'width': '34%'}}><strong>{grandTotal}</strong></td>
													</tr>
												</tbody>
											</table>
										</> : <></>}
										
											
									</div>
								</div>
							</div>
						</div>
					</div>
					<Modal show={exportModal} onHide={()=>setExportModal(false)} aria-labelledby="export-modal" backdrop="static" keyboard={false} centered>
						<Modal.Header closeButton>
							<Modal.Title id="export-modal">Basic Export</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<div className="my-1">
								Select options below to add more details to your report
								<div className="my-3">
									<div className="row">
										<div className="col-md">
											<div className="form-check">
												<input className="form-check-input" type="checkbox" value="primary" id="itemPrimary" checked={dlParams.includes('primary')} disabled={checkAll} onChange={setDownloadParams}/>
												<label className="form-check-label" htmlFor="itemPrimary"> All primary details </label>
											</div>
										</div>
									</div>
								</div>
								<div className="text-center">
									<button onClick={handleCSVExport} className="btn btn-sm btn-primary">Submit</button>
								</div>
							</div>
						</Modal.Body>
					</Modal>
                    <Common.FYNTicket url={props.url}/>
                </div>
            </div>
        </div>
    );
}